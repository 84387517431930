import React, { useState, useEffect,useRef }  from 'react';
import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import LogoComponent from '../components/LogoComponent';


const HomeComponent: React.FC = () => {

const { isAnnivContents } = useAppContext();


return(
    <section className="home">
        <LogoComponent componentName="" text='VALUEBOOK' />
        
        {isAnnivContents && (
            <div className="annivContents__title--wrap">
                <p className="annivContents__subTitle">10th Anniv. Special Contents</p>
                <div className="annivContents__titleLogo--wrap">
                    <span className="annivContents__titleLogo">Discover your Flags</span>
                    <i className="annivContents__titleLogoFlag flag"></i>
                </div>
            </div>
        )}
        <div className="home__btn--wrap">
            <Link to="/menu/" className="home__btn clickable">MENU</Link>
        </div>
    </section>
    )
}

export default HomeComponent;