import React, { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import { addDoc, collection, deleteDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../DB/firebase';
import { Flag } from './Utils/types';

interface FlagsComponentProps {
  category: string;
  sub_category: Record<string, any>;
}

const FlagsComponent: React.FC<FlagsComponentProps> = ({ category, sub_category = {} }) => {
  const {
    // isScreen,
    // setIsScreen,
    // isValueList,
    // setIsValueList,
    // isValueDetail,
    // setIsValueDetail,
    // isValueNum,
    // setIsValueNum,
    // isActionAndStance,
    // setIsActionAndStance,
    // isActionAndStanceNum,
    // setIsActionAndStanceNum,
    // isDepartment,
    // setIsDepartment,
    //isNavModalOpen,
    //setIsNavModalOpen,
    user,
    setUser,
    flags,
    setFlags,
    isAnnivContents,
  } = useAppContext();

  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [flagCount, setFlagCount] = useState<number | null>(null);
  const [userFlag, setUserFlag] = useState<Flag | null>(null);

  const handleRaiseFlag = async () => {
    setError('');
    setSuccess('');

    if (!user) {
      setError('ログインしてください。');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'flags'), {
        user_id: user.uid,
        category: category,
        sub_category: sub_category,
        status: true,
        created: Timestamp.now(),
      });
      setSuccess('フラッグを立てました。');
      setUserFlag({
        id: docRef.id,
        user_id: user.uid,
        category,
        sub_category,
        status: true,
        created: Timestamp.now(),
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleRemoveFlag = async () => {
    setError('');
    setSuccess('');

    if (!userFlag) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'flags', userFlag.id));
      setSuccess('フラッグを削除しました。');
      setUserFlag(null);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const countFlagsByCategoryAndConditions = (category: string, conditions?: { key: string, val: any }[]) => {
    if (!flags) return 0; // flagsがnullの場合は0を返す

    return flags.filter(flag => {
        if (flag.category !== category) return false;

        if (conditions) {
            // conditionsの内容をチェック
            const isValueNumOnly = conditions.length === 1 && conditions[0].key === 'valueNum';
            
            if (isValueNumOnly) {
                // valueNumのみが指定されている場合
                const subCategoryKeys = Object.keys(flag.sub_category);
                if (subCategoryKeys.length === 1 && subCategoryKeys[0] === 'valueNum') {
                    return conditions.every(condition => 
                        flag.sub_category[condition.key] === condition.val
                    );
                }
                return false; // 他のキーが存在する場合
            } else {
                // その他の条件が含まれている場合
                return conditions.every(condition => 
                    flag.sub_category[condition.key] === condition.val
                );
            }
        }
        
        return true; // 条件が存在しない場合、カテゴリーだけでフィルタリング
    }).length;
};




useEffect(() => {
  const conditions: { key: string, val: any }[] = [];
  Object.entries(sub_category).forEach(([key, val]) => {
    conditions.push({ key: key, val: val });
  });

  // Only proceed if flags is not null
  if (flags) {
    const count = countFlagsByCategoryAndConditions(category, conditions);
    setFlagCount(count);

    if (user) {
      const userFlag = flags.find(flag => {
        if (flag.user_id !== user.uid || flag.category !== category) {
          return false; // ユーザーIDやカテゴリーが一致しない場合はfalseを返す
        }

        // conditionsの内容をチェック
        const isValueNumOnly = conditions.length === 1 && conditions[0].key === 'valueNum';
        
        if (isValueNumOnly) {
            // valueNumのみが指定されている場合
            const subCategoryKeys = Object.keys(flag.sub_category);
            if (subCategoryKeys.length === 1 && subCategoryKeys[0] === 'valueNum') {
                return conditions.every(condition => 
                    flag.sub_category[condition.key] === condition.val
                );
            }
            return false; // 他のキーが存在する場合
        } else {
            // その他の条件が含まれている場合
            return conditions.every(condition => 
                flag.sub_category[condition.key] === condition.val
            );
        }
      });
      setUserFlag(userFlag || null);
    }
  }
}, [category, sub_category, flags, user]);

  /*useEffect(() => {
    const conditions: { key: string, val: any }[] = [];
    Object.entries(sub_category).forEach(([key, val]) => {
      conditions.push({ key: key, val: val });
    });

    // Only proceed if flags is not null
    if (flags) {
      const count = countFlagsByCategoryAndConditions(category, conditions);
      setFlagCount(count);

      if (user) {
        const userFlag = flags.find(flag => 
          flag.user_id === user.uid && 
          flag.category === category && 
          conditions.every(condition => flag.sub_category[condition.key] === condition.val)
        );
        setUserFlag(userFlag || null);
      }
    }
  }, [category, sub_category, flags, user]);*/



  if(!isAnnivContents){
    return
  }
  return (
    <>
    {user && (
    <div className="flags">
      {userFlag ? (
        <button className="flags__btn flags__btn--remove" onClick={handleRemoveFlag}><i className="flags__btn__flag flag"></i>{flagCount !== null && <span className="flags__btn__count">{flagCount}</span>}<span className="flags__btn__text">Flagを削除</span></button>
      ) : (
        <button className="flags__btn flags__btn--add" onClick={handleRaiseFlag}><i className="flags__btn__flag flag"></i>{flagCount !== null && flagCount !== 0 && <span className="flags__btn__count">{flagCount}</span>}<span className="flags__btn__text">Flagを立てる</span></button>
      )}
    </div>
    )}
    </>
    // <div className="flags">
    // {user ? (
    //   userFlag ? (
    //     <button className="flags__btn flags__btn--remove" onClick={handleRemoveFlag}><i className="flags__btn__flag flag"></i>{flagCount !== null && <span className="flags__btn__count">{flagCount}</span>}<span className="flags__btn__text">Flagを削除</span></button>
    //   ) : (
    //     <button className="flags__btn flags__btn--add" onClick={handleRaiseFlag}><i className="flags__btn__flag flag"></i>{flagCount !== null && flagCount !== 0 && <span className="flags__btn__count">{flagCount}</span>}<span className="flags__btn__text">Flagを立てる</span></button>
    //   )
    // ) : (
    //   <button className="flags__btn flags__btn--add"><i className="flags__btn__flag flag"></i>{flagCount !== null && flagCount !== 0 && <span className="flags__btn__count">{flagCount}</span>}</button>
    // )}
    // </div>
  )
};

export default FlagsComponent;
