import React from 'react';

const NotFoundComponent: React.FC = () => {
  return (
    <div className="not_found">
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <a href="/">Go to Home</a>
    </div>
  );
};

export default NotFoundComponent;