import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

  // dev env nagatani's firebase
  // const firebaseConfig = {
  //   apiKey: "AIzaSyDm9wAyDfS8f86csoIQ72PqN2b3l23N4y0",
  //   authDomain: "value-book-539a6.firebaseapp.com",
  //   projectId: "value-book-539a6",
  //   storageBucket: "value-book-539a6.appspot.com",
  //   messagingSenderId: "847726998212",
  //   appId: "1:847726998212:web:961e4e96793976218a4f91"
  // };

  //config for product
  const firebaseConfig = {
    apiKey: "AIzaSyB-SSKhH0uO2fsa9QnN4RyJnPDvZMNglRI",
    authDomain: "discover-your-flags.firebaseapp.com",
    projectId: "discover-your-flags",
    storageBucket: "discover-your-flags.appspot.com",
    messagingSenderId: "450261043461",
    appId: "1:450261043461:web:60a03a720ee175041b4f6c"
  };
  


// Firebaseの初期化
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

export { app, db, auth };
