import React from 'react';
import { useEffect, useRef, useState, RefObject } from 'react';
import { useAppContext } from '../../AppContext';
import useLocationHook from '../Hooks/useLocationHook'; // カスタムフックのインポート
import usePinnedElementsHook from '../Hooks/usePinnedElementsHook'; // カスタムフックのインポート
import Animated from './components/AnimatedComponent';
import { mission } from '../../data/Mission';
import { value } from '../../data/Value';
import { FaCircleArrowRight } from "react-icons/fa6";
import { FaMapPin } from "react-icons/fa6";


export const nl2br = (str: string): string => {
  // 改行コード（\n）を、<br>タグに置き換える
  return str.replace(/\n/g, '<br>');
}

  
  // spanタグに特定のclass名を付与する関数
  export const addClassToSpanTags = (html: string, className: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const spanTags = doc.querySelectorAll('span');
  
    spanTags.forEach((spanTag) => {
      spanTag.classList.add(className);
    });
  
    return doc.body.innerHTML;
  };




  // pタグをAnimated コンポーネントで囲む関数
  
  export const WrapPTagsWithAnimated = (html: string): React.ReactNode[] => {
    const location = useLocationHook();
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const pTags = doc.querySelectorAll('p');
    const elements: React.ReactNode[] = [];
  
    pTags.forEach((pTag, index) => {
      // ここにpinnedElementsのオブジェクトと比較するロジックを入れる。componentが違った場合のパターンを書かないといけない。URLで判別？stateで管理した場合変更が入ると再レンダーが起こることは問題がないか？
      let elementId = genElementId(index,location);
      let pinnedElementStatus = false;
      if(elementId){
        pinnedElementStatus = CheckPinnedElements(elementId);
      }
        
      elements.push(
        <Animated key={index}>
            {!pinnedElementStatus ? (
              <p id={elementId} dangerouslySetInnerHTML={{ __html: pTag.innerHTML }} />
            ):(
              <p id={elementId} className="pinned" dangerouslySetInnerHTML={{ __html: pTag.innerHTML }} />
            )}
        </Animated>
      );
    });
  
    return elements;
  };

  // pinnedアイコンを返すコンポーネント
  const PinnedIcon = () => {
    return(
      <span className="pinnedIcon--wrap">
        <FaMapPin className="pinnedIcon"/>
      </span>
    )
  }

  //mission mission_introduction value_detail
  const genElementId = (index:number,location:any) => {
    let elementId;
    if(location.pathname === '/mission/'){
      elementId = `mission_description_${index + 1}`;
    } else if(location.pathname === '/mission/introduction/'){
      elementId = `mission_introduction_${index + 1}`;
    } else if(location.pathname.includes('/value/')){
      if(location.pathname.includes('/action_and_stance/')){
        elementId = `mission_introduction_${index + 1}`;
      } else {
        const regex = /\/value\/detail\/(\d+)\//;
        const match = location.pathname.match(regex);
        const id = match ? match[1] : 'No ID found';
        elementId = `value_${id}_description_${index + 1}`;
      }
    }
    return elementId;
  }

  
  // Action & Stance で 出力されるpタグにidを付与する関数
  export const addIdToActionAndStancePTags = (html: string, id: string):string => { // 最後の:stringの部分は戻り値の型定義（確実に戻り値を持つ場合に設定）
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const pTags = doc.querySelectorAll('p');
    pTags.forEach((pTag) => {
      pTag.id = id;
      if(CheckPinnedElements(id)){
        pTag.classList.add('pinned');
      }
    });
    return doc.body.innerHTML;
  }

  export const checkPinnedElmInActionAndStancePTags = (html: string):string => { // 最後の:stringの部分は戻り値の型定義（確実に戻り値を持つ場合に設定）
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const pTags = doc.querySelectorAll('p');
    pTags.forEach((pTag) => {
      if(CheckPinnedElements(pTag.id)){
        pTag.classList.add('pinned');
      }
    });
    return doc.body.innerHTML;
  }
  


  export const CheckPinnedElements = (id:string):boolean =>{
    const {pinnedElements} = usePinnedElementsHook();
   // const { pinnedElements } = useAppContext() as { pinnedElements: string[] };
    // pinnedElementsが空でないことを確認し、includesを安全に呼び出す
    return pinnedElements.length > 0 && pinnedElements.includes(id); //true or false
  }

  

  // mission,mission_introdusction,value_detail は WrapPTagsWithAnimated 時に、action_and_stanceは　addIdToActionAndStancePTags時にeventListnerとして設定
  export const TogglePinnedElementClick = (elm: HTMLElement,pinnedElements: string[]): string[] => {
    // 現在のpinnedElementsのコピーを作成
    let pinnedElementsArray = [...pinnedElements];
    let newPinnedElementsArray;
  
    if (elm.classList.contains('pinned')) {
      // pinnedクラスがある場合、配列からidを削除
      newPinnedElementsArray = pinnedElementsArray.filter(n => n !== elm.id);
      elm.classList.remove('pinned');
    } else {
      // pinnedクラスがない場合、配列にidを追加
      pinnedElementsArray.push(elm.id);
      newPinnedElementsArray = pinnedElementsArray;
      elm.classList.add('pinned');
    }
    return newPinnedElementsArray;
  };

  

  // html内のBRタグを除去する関数

  export const removeBrTags = (htmlString: string): string => {
    // 正規表現を使って <br /> タグや <br class="..."> タグを空文字列に置換
    return htmlString.replace(/<br\s*(\/|class="[^"]*")?\s*>/gi, '');
  }


  // ターゲットが消えたら要素を出現させる関数


type IntersectionCallback = (isIntersecting: boolean) => void;

interface IntersectionOptions extends IntersectionObserverInit {}

export const useIntersectionObserver = (
  targetRef: RefObject<Element>,
  callback: IntersectionCallback,
  options: IntersectionOptions
) => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        callback(entry.isIntersecting);
      });
    }, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, options, callback]);
};


//クリックしたpタグにpinnedクラスを付与 各component内でaddEventListnerを設定

export const togglePinned = (event: Event) => {
  const target = event.currentTarget as HTMLParagraphElement;
  if (target.classList.contains('pinned')) {
    target.classList.remove('pinned');
  } else {
    target.classList.add('pinned');
  }
};

// リンク末尾に配置しホバー時にアニメーションを返すコンポーネント
export const LinkArrow = () => {
  return(
    <span className="linkArrow">
      <FaCircleArrowRight className="linkArrowIcon"/>
    </span>
  )
}

