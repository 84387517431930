// LoginForm.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { useAppContext } from '../AppContext';
import { getAuth, signInWithEmailAndPassword, User, signOut,updatePassword } from "firebase/auth";
import { Link, useLocation } from 'react-router-dom';
import { Comment, Flag, Like } from './Utils/types';
import { Mission, mission } from '../data/Mission';
import { Value, value } from '../data/Value';
import { Departments, departments } from '../data/Departments';
import { removeBrTags } from './Utils/helpers';
import { FaHeart,FaRegComment } from "react-icons/fa6";

import { app } from '../DB/firebase';

const auth = getAuth(app);

const UserLoginComponent: React.FC = () => {

  const location = useLocation();

  const { user, setUser, member, flags, comments, likes } = useAppContext();
  const [changePasswordScreen, setChangePasswordScreen] = useState<boolean>(false);
  
  const [userComments, setUserComments] = useState<Comment[] | null>(null);
  const [userFlags, setUserFlags] = useState<Flag[] | null>(null);
  const [userLikeComments, setUserLikeComments] = useState<Comment[] | null>(null);


  
//username
const username = member?.find((m) => m.id === user?.uid)?.username;




//userComments

const filteredComments = useCallback(() => { //再計算を防ぐ
  if (comments === null || user === null) {
      return;
  }
  if (comments.length > 0) {
      const filteredComments = comments
      .filter(comment => comment.user_id === user.uid)
      .sort((a, b) => (a.created < b.created ? 1 : -1));
      setUserComments(filteredComments);
      }
  }, [comments, user]); // useCallbackの依存配列にcomments、userを追加することで、これらの値が変化したときにfilteredComments関数が再生成されるように。


  // userのcomment一覧 flags一覧(mission,value,actionAndStance,他のユーザーのcommentに分ける)

  useEffect(() => {
    filteredComments()
  }, [filteredComments]); // useEffectの依存関係にfilteredCommentsを指定することで、filteredComments関数が更新されたときに再実行されるように。


  //userFlags

  const filteredFlags = useCallback(() => { //再計算を防ぐ
    if (flags === null || user === null) {
        return;
    }
    if (flags.length > 0) {
      const filteredFlags = flags
      .filter(flags => flags.user_id === user.uid)
      .sort((a, b) => (a.created < b.created ? 1 : -1));// createdの降順でsort

      setUserFlags(filteredFlags);
    }
    }, [user,flags]);
  


  useEffect(() => {
    filteredFlags()
  }, [filteredFlags]);



  //userLikeComments

  const filteredLikeComments = useCallback(() => { //再計算を防ぐ
    if (likes === null || user === null || comments === null) {
        return;
    }
    if (likes.length > 0) {
      const filteredLikes = likes
      .filter(likes => likes.user_id === user.uid)
      .sort((a, b) => (a.created < b.created ? 1 : -1));// createdの降順でsort

      const filteredLikeComments = comments
      .filter(comment => filteredLikes.some(like => like.comment_id === comment.id));

      setUserLikeComments(filteredLikeComments);
    }

  }, [user,likes]);
  

  useEffect(() => {
    filteredLikeComments()
  }, [user,filteredLikeComments]);



  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null); // ユーザーをnullに設定してログアウト状態にする
    } catch (error: any) {
      console.error('ログアウトエラー:', error.message);
    }
  };


  // login-form

  const UserLoginForm = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [isNextInput, setIsNextInput] = useState<boolean>(false);

    const handleNextBtnClick = () => {
      if (!email) {
        setError('メールアドレスを入力してください。');
        return;
      }
      setIsNextInput(true);
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setError('');
  
      if (!email) {
        setError('メールアドレスを入力してください。');
        return;
      }
  
      if (!password) {
        setError('パスワードを入力してください。');
        return;
      }
  
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const loggedInUser = userCredential.user;
        setUser(loggedInUser);
      } catch (error: any) {
        console.log('login error',error.message);
        setError('正しいメールアドレスとパスワードをご入力ください。');
      }
    };


    useEffect(() => {
      if(email){
        setError('');
      }
      if(password){
        setError('');
      }
    }, [email,password]);


    return(
      <div className="mypage__contents login-form">
          <h2 className="login-form__title">ログイン</h2>
          <form onSubmit={handleSubmit}>
            {!isNextInput && (
              <div className="login-form__item login-form__item--email">
                <label htmlFor="email">メールアドレス</label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
            )}
            {isNextInput && (
              <div className="login-form__item login-form__item--password">
                <label htmlFor="password">パスワード</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            )}
            <div className="login-form__btns-wrap">
            {!isNextInput && (
              <button className="login-form__next-btn common-btn" type="button" onClick={handleNextBtnClick}>次へ</button>
            )}
            {isNextInput && (
              <>
              <button className="common-btn--gray" type="button" onClick={()=>{setIsNextInput(false)}}>戻る</button>
              <button className="common-btn" type="submit">ログイン</button>
              </>
            )}
            </div>
            {error && <p className="login-form__error-message">{error}</p>}
          </form>
        </div>
    )
  }


  // change password form
  const ChangePasswordForm = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [isNextInput, setIsNextInput] = useState<boolean>(false);

    const handleNextBtnClick = () => {
      if (!newPassword) {
        setError('パスワードを入力してください。');
        return;
      }
      setIsNextInput(true);
    }

    const handleSubmit = async (e: React.FormEvent) => {
      e.preventDefault();
  
      if (newPassword !== confirmPassword) {
        setError('パスワードが一致しません。');
        return;
      }
  
      const user = auth.currentUser;
      if (user) {
        try {
          await updatePassword(user, newPassword);
          setSuccess(true);
          setError(null);
        } catch (err: any) {
          setError(err.message);
        }
      }
    };

    useEffect(() => {
      if(newPassword){
        setError('');
      }
      if(confirmPassword){
        setError('');
      }
    }, [newPassword,confirmPassword]);

    return(  
      <div className="mypage__contents change-password-form">
      <h2 className="change-password-form__title">パスワードの変更</h2>
      {success ? (
        <>
          <p className="change-password-form__success-message">パスワードが変更されました。</p>
          <button className="common-btn--gray" onClick={()=>{setChangePasswordScreen(false)}}>マイページに戻る</button>
        </>
      ) : (
        <form onSubmit={handleSubmit}>
          {!isNextInput && (
          <div className="change-password-form__item change-password-form__item--new-password">
            <label>新しいパスワード ※6文字以上</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          )}
          {isNextInput && (
            <div className="change-password-form__item change-password-form__item--confirm-password">
              <label>新しいパスワード（再入力）</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
          )}
          <div className="change-password-form__btns-wrap">
          {!isNextInput && (
            <>
              <button className="common-btn--gray" onClick={()=>{setChangePasswordScreen(false)}}>マイページに戻る</button>
              <button className="common-btn" type="button" onClick={handleNextBtnClick}>次へ</button>
            </>
          )}
          {isNextInput && (
            <>
              <button className="common-btn--gray" type="button" onClick={()=>{setIsNextInput(false)}}>戻る</button>
              <button className="common-btn" type="submit">パスワードを変更</button>
            </>
            )}
          </div>
          {error && <p className="change-password-form__error-message">{error}</p>}
        </form>
      )}
      {!success && (
        <p className="change-password-form__footnote">
          エラーが出てパスワードが変更できない場合には、再ログイン後にお試しください。
        </p>
      )}
    </div>
    )
  }

  useEffect(() => {
    setChangePasswordScreen(false);
  }, [location]);

  

  return (
    <section className="mypage">
        <header className="mypage__header">
            {/* <Link to={"/menu/"} className="mypage__header__menu clickable">
                MENU
            </Link> */}
            <Link to={"/mypage/"} className="mypage__header__title clickable">
              マイページ
            </Link>
        </header>
        {user ? (
          changePasswordScreen ? (
            <ChangePasswordForm />
          ) : (
          <div className="mypage__contents">
              <div className="mypage__contents__login-info">
                  <p className="mypage__contents__login-info__username">ログインユーザー: {username}</p>
                  <button className="mypage__contents__login-info__logout-btn" onClick={handleLogout}>ログアウト</button>
                  <button className="mypage__contents__login-info__password-change-btn" onClick={()=>{setChangePasswordScreen(true)}}>パスワード変更</button>
              </div>

              {/* flags */}
              <div className="mypage__contents__userFlags">
                  <div className="mypage__contents__userFlags__title--wrap">
                      <i className="mypage__contents__userFlags__titleFlagIcon flag"></i>
                      <p className="mypage__contents__userFlags__title">あなたの立てたFlag</p>
                  </div>
                  {userFlags !== null && userFlags.length !==0 ? (
                      <ul className="mypage__contents__userFlags__list">
                          {userFlags.map((flag, index) => {
                              // リンク先を動的に生成
                              const genFlgsLink = () => {
                                let link = '/';
                                if (flag.category === 'value') {
                                    if ('actionAndStanceNum' in flag.sub_category) {
                                      link = `/value/detail/${flag.sub_category.valueNum + 1}/action_and_stance/${flag.sub_category.department}/#action${Number(flag.sub_category.actionAndStanceNum) + 1}`;
                                    } else {
                                      link = `/value/detail/${flag.sub_category.valueNum + 1}/`;
                                    }
                                } else if (flag.category === 'mission') {
                                    link = '/mission/';
                                }
                                return link;
                              };
                              return (
                                  <li key={index}>
                                      {flag.category === 'mission' ? (
                                      <Link to={genFlgsLink()} className="mypage__contents__userFlags__list--inner clickable">
                                          <i className="mypage__contents__userFlags__list__flagIcon flag"></i>
                                          <div>
                                              <span>{mission.title} </span>
                                              <span>{removeBrTags(mission.text)}</span>
                                          </div>
                                      </Link>
                                      ) : (
                                      <Link to={genFlgsLink()} className="mypage__contents__userFlags__list--inner clickable">
                                          <i className="mypage__contents__userFlags__list__flagIcon flag"></i>
                                          <div>
                                              <span>VALUE{value.values[flag.sub_category.valueNum].number} </span>
                                              <span>{removeBrTags(value.values[flag.sub_category.valueNum].value)}</span>
                                              {flag.sub_category.actionAndStanceNum && (
                                                  <span>
                                                      <span> ≫ アクション&スタンス</span>
                                                      <span> ≫ {value.values[flag.sub_category.valueNum].actionAndStance[flag.sub_category.actionAndStanceNum].title} ≫ {departments[flag.sub_category.department]}</span>
                                                  </span>
                                              )}
                                          </div>
                                      </Link>
                                      )}
                                  </li>
                              )
                          })}
                      </ul>
                  ):(
                      <p>まだFlagを立てていません。</p>
                  )}
              </div>
              
              {/* comments */}
              <div className="mypage__contents__userComments">
                  <div className="mypage__contents__userComments__title--wrap">
                      <FaRegComment className="mypage__contents__userComments__titleCommentIcon"/>
                      <p className="mypage__contents__userComments__title">あなたのコメント</p>
                  </div>
              {userComments !== null && userComments.length !==0 ? (
                  <ul className="mypage__contents__userComments__list">
                  {userComments.map((comment, index) => {
                      // リンク先を動的に生成
                      const genCommentsLink = () => {
                        let link = '/';
                        if (comment.category === 'value') {
                            if ('actionAndStanceNum' in comment.sub_category) {
                              link = `/value/detail/${comment.sub_category.valueNum + 1}/action_and_stance/${comment.sub_category.department}/#action${Number(comment.sub_category.actionAndStanceNum) + 1}`;
                            } else {
                              link = `/value/detail/${comment.sub_category.valueNum + 1}/`;
                            }
                        } else if (comment.category === 'mission') {
                            link = '/mission/';
                        }
                        return link;
                      };

                      return (
                      <li key={index}>
                          <Link to={genCommentsLink()} className="mypage__contents__userComments__list--inner clickable">
                          <div className="mypage__contents__userComments__list__comment__category-wrap">
                              <FaRegComment className="mypage__contents__userComments__list__commentIcon"/>
                              {comment.category === 'mission' ? (
                                <div>
                                  MISSION
                                </div>
                              ) : (
                              <div>
                                  <span>VALUE{value.values[comment.sub_category.valueNum].number} </span>
                                  <span>{removeBrTags(value.values[comment.sub_category.valueNum].value)}</span>
                                  {comment.sub_category.actionAndStanceNum && (
                                      <span>
                                          <span> ≫ アクション&スタンス</span>
                                          <span> ≫ {value.values[comment.sub_category.valueNum].actionAndStance[comment.sub_category.actionAndStanceNum].title} ≫ {departments[comment.sub_category.department]}</span>
                                      </span>
                                  )}
                              </div>
                              )}
                              </div>
                              <p className="mypage__contents__userComments__list__comment">{comment.comment}</p>
                          </Link>
                      </li>
                      );
                  })}
                  </ul>
              ) : (
                  <p>まだコメントしていません。</p>
              )}
              </div>

              {/* likeComments */}
              <div className="mypage__contents__userComments">
                  <div className="mypage__contents__userComments__title--wrap">
                      <FaHeart className="mypage__contents__userComments__title__likeIcon" />
                      <p className="mypage__contents__userComments__title">いいねしたコメント</p>
                  </div>
              {userLikeComments !== null && userLikeComments.length !==0 ? (
                  <ul className="mypage__contents__userComments__list">
                  {userLikeComments.map((comment, index) => {
                      // リンク先を動的に生成
                      const genCommentsLink = () => {
                        let link = '/';
                        if (comment.category === 'value') {
                            if ('actionAndStanceNum' in comment.sub_category) {
                              link = `/value/detail/${comment.sub_category.valueNum + 1}/action_and_stance/${comment.sub_category.department}/#action${Number(comment.sub_category.actionAndStanceNum) + 1}`;
                            } else {
                              link = `/value/detail/${comment.sub_category.valueNum + 1}/`;
                            }
                        } else if (comment.category === 'mission') {
                            link = '/mission/';
                        }
                        return link;
                      };

                      return (
                      <li key={index}>
                          <Link to={genCommentsLink()} className="mypage__contents__userComments__list--inner clickable">
                              <div className="mypage__contents__userComments__list__comment__category-wrap">
                                <FaRegComment className="mypage__contents__userComments__list__commentIcon"/>
                                {comment.category === 'mission' ? (
                                  <div className="mypage__contents__userComments__list__comment__category">MISSION</div>
                                ) : (
                                  <div className="mypage__contents__userComments__list__comment__category">
                                      <span>VALUE{value.values[comment.sub_category.valueNum].number} </span>
                                      <span>{removeBrTags(value.values[comment.sub_category.valueNum].value)}</span>
                                      {comment.sub_category.actionAndStanceNum && (
                                          <span>
                                              <span> ≫ アクション&スタンス</span>
                                              <span> ≫ {value.values[comment.sub_category.valueNum].actionAndStance[comment.sub_category.actionAndStanceNum].title} ≫ {departments[comment.sub_category.department]}</span>
                                          </span>
                                      )}
                                  </div>
                                )}
                              </div>
                              <div className="mypage__contents__userComments__list__comment-wrap">
                                <p className="mypage__contents__userComments__list__comment">{comment.comment}</p>
                                <span className="mypage__contents__userComments__list__username">
                                  {member?.find((m) => m.id === comment.user_id)?.username || 'Unknown User'}
                                </span>
                              </div> 
                          </Link>
                      </li>
                      );
                  })}
                  </ul>
              ) : (
                  <p>まだいいねをしていません。</p>
              )}
              </div>
          </div>
        )
      ) : (
        <UserLoginForm />
      )}
    </section>
  );
};

export default UserLoginComponent;
