import React, { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import { Link, useLocation } from 'react-router-dom';
import NavigationComponent from '../components/NavigationComponent';
import { mission,Mission } from '../data/Mission';
import { value, Value} from '../data/Value';
import { departments,Departments } from '../data/Departments';
import LogoComponent from '../components/LogoComponent';
import CommentFormComponent from '../components/CommentFormComponent';
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from '../DB/firebase';
import { Button, Typography, Switch, FormControlLabel, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FaRegMap } from "react-icons/fa6";
import { removeBrTags } from './Utils/helpers';


const FooterComponent: React.FC = () => {

  const location = useLocation();
  
  const {
    // isScreen,
    // setIsScreen,
    user,
    isDepartment,
    isNavModalOpen,
    setIsNavModalOpen,
    isAnnivContents,
    isCommentFormOpen,
    setIsCommentFormOpen,
    setCommentCategoryInfo
  } = useAppContext();



    useEffect(() => {
      setIsCommentFormOpen(false)
      setIsNavModalOpen(false); //ナビゲーションモーダル閉じる
      setCommentCategoryInfo(null);
    }, [location]);

    


    const getBtnStateAndSetClassName = () =>{
        if(isNavModalOpen){
            return(
                'footer__navToggleBtn isOpenBtnPushed'
            )
        } else {
            return(
                'footer__navToggleBtn isOpenBtnNotPushed'
            )
        }
    }

    const handleNavToggleBtnClick = () =>{
      setIsNavModalOpen(!isNavModalOpen);
      setIsCommentFormOpen(false);
    }

    return(
      <>
      {location.pathname !== '/' && (
        <>
            {isAnnivContents && (
                <CommentFormComponent/>
            )}
            <footer className={`footer ${isCommentFormOpen && ('isCommentFormOpen')}`}>
                {isAnnivContents && (
                <Link
                  to="/mypage/"
                  className={`footer__annivContents-mypageBtn clickable ${isNavModalOpen ? 'isNavModalOpen':''}`}
                >
                {user ? (
                    <>マイページへ</>
                ):(
                    <>ログイン</>
                )}
                </Link>
                )}
                <button className={getBtnStateAndSetClassName()} onClick={()=>handleNavToggleBtnClick()}>
                    <FaRegMap className="footer__navToggleBtnIcon" />
                    <span>
                    {!isNavModalOpen ? (
                        'マップを開く'
                        ) : (
                        'マップを閉じる'
                    )}
                    </span>
                </button>
            </footer>
        </>
        )}
      </>
    )
  }

export default FooterComponent;