import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTopOnLocationChangeHook = () => {
  const location = useLocation();

  useEffect(() => {
    // locationが変化した際にページのトップにスクロールする
    const handleScroll = () => {
      window.scrollTo(0, 0);
    };

    //ACTION AND STANCE（#id付きURL）とその他で分岐
    const hash = location.hash;//#id付きURL
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        const LOGOHEADER = document.querySelector('.logo--header')?.clientHeight;
        const VALUEHEADER = document.querySelector('.value__header')?.clientHeight;
        const ACTIONANDSTANCEHEADER = document.querySelector('.actionAndStance__header')?.clientHeight;
        const SCROLLMARGIN = 30;
        const HEADER_HEIGHT = Number(LOGOHEADER) + Number(VALUEHEADER) + Number(ACTIONANDSTANCEHEADER) + Number(SCROLLMARGIN);
        setTimeout(() => {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition + window.scrollY - HEADER_HEIGHT;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
          });
        },200);
      }
    } else {
      handleScroll();
    }

    //const timeoutId = setTimeout(handleScroll, 200);

    // クリーンアップ関数
    //return () => clearTimeout(timeoutId);

  }, [location]);
};

export default useScrollToTopOnLocationChangeHook;
