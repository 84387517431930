import React, { useState, useEffect,useRef }  from 'react';
import { useAppContext } from '../AppContext';
import { Link,useParams,useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Value, value} from '../data/Value';
import Animated from './Utils/components/AnimatedComponent';
import useScrollToTopOnLocationChange from './Hooks/useScrollToTopOnLocationChangeHook';
import { addClassToSpanTags, WrapPTagsWithAnimated, removeBrTags, useIntersectionObserver, TogglePinnedElementClick,LinkArrow } from './Utils/helpers';
import CommentComponent from './CommentComponent';
import FlagsComponent from './FlagsComponent';

interface RouteParams {
    value_num: string;
    [key: string]: string | undefined;
}

interface ValueDetailComponentProps {
    value: Value;
}

const ValueDetailComponent: React.FC<ValueDetailComponentProps> = ({value}) => {

    const location = useLocation();
    const { value_num } = useParams<RouteParams>();
    const valueNum = value_num ? parseInt(value_num, 10) - 1 : 1; // 文字列から数値に変換、undefinedの場合は0

    const {
        // isScreen,
        // setIsScreen,
        // isValueList,
        // setIsValueList,
        // isValueDetail,
        // setIsValueDetail,
        isValueNum,
        setIsValueNum,
        // isActionAndStance,
        // setIsActionAndStance,
        // isActionAndStanceNum,
        // setIsActionAndStanceNum,
        isDepartment,
        setIsDepartment,
        user,
        comments,
        pinnedElements,
        setPinnedElements,
    } = useAppContext();

    

    //const actionAndStances = Object.values(value.values[isValueNum].actionAndStance);

    // value -> No. title descriptionリンク　アクスタリンク（タイトル一覧）
    // valueDetail ->  No. title description 他valueリスト　アクスタリンク（タイトル一覧）
    // actionAndStance -> No. title descriptionリンク　スライダー形式　部署ごとの切り替えリンク
    

    //screen と isValueList のstateによってvalueのリスト一覧 or 個別のvalue詳細を返すコンポーネント


    // valueDetail時にタイトルが隠れたらヘッダーを表示させる
    const targetRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleIntersection = (isIntersecting: boolean) => {
        setIsVisible(!isIntersecting);
    };

    //titleのheaderを出現させるターゲット
    useIntersectionObserver(targetRef, handleIntersection, { threshold: 0.8 });

    //ページが切り替わった際にトップにスクロールするHook(useEffect)
    useScrollToTopOnLocationChange();

    // ページのデフォルトのstateをセット
    useEffect(() => {
        setIsValueNum(valueNum);
    }, [location]);

    // descriptionのpタグにpinnedクラスを付与する
    useEffect(() => {
        const pElements = document.querySelectorAll('.valueDetail__description p');
        
        const handleClick = (event: MouseEvent) => {
            const targetElement = event.target as HTMLElement;
            // targetElementがspanタグであれば、その最も近い親のp要素を取得する
            let pElement
            if(targetElement.tagName.toLowerCase() === 'span'){
                pElement = targetElement.closest('p');
            } else {
                pElement = targetElement;
            }
            if(!pElement){
                return;
            }
            let newPinnedElementsArray = TogglePinnedElementClick(pElement, pinnedElements);
            setPinnedElements(newPinnedElementsArray);
        };

        pElements.forEach(p => {
            const pElement = p as HTMLElement; // 型アサーションを使用してHTMLElementとして扱う
            pElement.addEventListener('click', handleClick);
        });
        
        // Cleanup function to remove event listeners
        return () => {
            pElements.forEach(p => {
                const pElement = p as HTMLElement; // 型アサーションを使用してHTMLElementとして扱う
                pElement.removeEventListener('click', handleClick);
            });
        };
    }, [pinnedElements]);
    
    const valueDetail = value.values[isValueNum];
    return(
        <div className="value">
            <header className="value__header">
                <Link to={"/menu/"} className="value__header__menu clickable">
                    MENU
                </Link>
                <Link to={"/value/"} className="clickable">
                    <span className="value__header__title">{value.title}</span>
                    <span className="value__header__subTitle">{value.subTitle}</span>
                </Link>
            </header>
            <div className="valueDetail">
                <motion.header
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isVisible ? 1 : 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <header className="valueDetail__header">
                        <div className="valueDetail__header__number--wrap">
                            <span className="valueDetail__header__numberValue">VALUE</span>
                            <span className="valueDetail__header__number">{valueDetail.number}</span>
                        </div>
                        <span className="valueDetail__header__title">{removeBrTags(valueDetail.value)}</span>
                    </header>
                </motion.header>
                
                
                <div>
                    <h3 ref={targetRef} className="valueDetail__heading">
                        <div className="valueDetail__heading__number--wrap">
                            <div className="valueDetail__heading__numberValue">VALUE</div>
                            <div className="valueDetail__heading__number">{valueDetail.number}</div>
                        </div>
                        <span className="valueDetail__heading__title" dangerouslySetInnerHTML={{ __html: valueDetail.value }}/>
                        <FlagsComponent category='value' sub_category={{valueNum:isValueNum}}/>
                    </h3>
                    <p className="valueDetail__descriptionTitle">DESCRIPTION</p>
                    {/*<p dangerouslySetInnerHTML={{__html: valueDetail.description}}></p>*/}
                    <div className="valueDetail__description">{WrapPTagsWithAnimated(addClassToSpanTags(valueDetail.description, 'text--strong'))}</div>
                    <p className="common-fukidashi">このVALUEの</p>
                    <Link to={`/value/detail/${value_num}/action_and_stance/${isDepartment}/`} className="valueDetail__actionAndStance clickable">ACTION & STANCEへ<LinkArrow /></Link>

                    <CommentComponent category='value' sub_category={{valueNum:isValueNum}}/>
                </div>
                
            </div>
        </div>
    )
}

export default ValueDetailComponent;
