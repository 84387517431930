//import { posix } from "path";
import { useAppContext } from '../AppContext';
import { Link,useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

const getLogoCharacterStyle = (character: string): React.CSSProperties => {
    if(character ==='E'){
        return{
            marginRight:'0.4em'
        }
    } else {
        return{
            marginRight:'0'
        }
    }
};

const LogoText = ({ character }: { character: string }) => {
    
    return(
    <span style={{...getLogoCharacterStyle(character)}}>
        {character}
    </span>
    )
}

interface LogoComponentProps {
  componentName: string;
  text: string;
}




const LogoComponent: React.FC<LogoComponentProps> = ({ text, componentName }) => {

    const location = useLocation();

    const getLogoClassName = () => {
        if(location.pathname === '/'){
            return(
                'clickable commonValuebookLogoStyle valuebookLogoStyle--home'
            )
        } else {
            return(
                'clickable commonValuebookLogoStyle valuebookLogoStyle--header'
            )
        }
    };


    let arraySplitText = text.split('');
    return(
        <Link to={"/"}
          className={getLogoClassName()}
        >
            {arraySplitText.map((data,index) =>{
                return(
                    <LogoText key={`logo_text_${index}`} character={data} />
                )
            })}
        </Link> 
    )
}

export default LogoComponent;