export interface Departments {
    [key: string]: string;
}

export const departments: Departments = {
    company:'ディスカバリー全社',
    acountPlanning:'営業部',
    mediaPlanning:'運用部',
    creative:'制作部',
    corporate:'経営企画部／経営管理部'
}