import React, { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import { addDoc, collection, deleteDoc, doc, Timestamp } from "firebase/firestore";
import { db } from '../DB/firebase';
import { Like } from './Utils/types';
import { FaHeart } from "react-icons/fa6";

interface LikesComponentProps {
  comment_id: string;
}

const LikesComponent: React.FC<LikesComponentProps> = ({comment_id}) => {
  const {
    user,
    likes,
    setLikes,
    isAnnivContents,
  } = useAppContext();

  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [likeCount, setLikeCount] = useState<number | null>(null);
  const [userLike, setUserLike] = useState<Like | null>(null);

  const handlePushLikeBtn = async () => {
    setError('');
    setSuccess('');

    if (!user) {
      setError('ログインしてください。');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'likes'), {
        user_id: user.uid,
        comment_id: comment_id,
        status: true,
        created: Timestamp.now(),
      });
      setSuccess('いいねしました。');
      setUserLike({
        id: docRef.id,
        user_id: user.uid,
        comment_id,
        status: true,
        created: Timestamp.now(),
      });
    } catch (error: any) {
      setError(error.message);
    }
  };

  const handleRemoveLike = async () => {
    setError('');
    setSuccess('');

    if (!userLike) {
      return;
    }

    try {
      await deleteDoc(doc(db, 'likes', userLike.id));
      setSuccess('いいねを取り消しました。');
      setUserLike(null);
    } catch (error: any) {
      setError(error.message);
    }
  };


/*
  // comment_idが同じかどうかでカウントするように書き換える
  const countLikesByCategoryAndConditions = (category: string, conditions?: { key: string, val: any }[]) => {
    if (!likes) return 0; // likesがnullの場合は0を返す

    return likes.filter(like => {
        if (like.category !== category) return false;

        if (conditions) {
            // conditionsの内容をチェック
            const isValueNumOnly = conditions.length === 1 && conditions[0].key === 'valueNum';
            
            if (isValueNumOnly) {
                // valueNumのみが指定されている場合
                const subCategoryKeys = Object.keys(like.sub_category);
                if (subCategoryKeys.length === 1 && subCategoryKeys[0] === 'valueNum') {
                    return conditions.every(condition => 
                        like.sub_category[condition.key] === condition.val
                    );
                }
                return false; // 他のキーが存在する場合
            } else {
                // その他の条件が含まれている場合
                return conditions.every(condition => 
                    like.sub_category[condition.key] === condition.val
                );
            }
        }
        
        return true; // 条件が存在しない場合、カテゴリーだけでフィルタリング
    }).length;
};*/


// comment_idが同じかどうかでカウントするように書き換える
const countLikesByCommentId = (comment_id: string) => {
    if (!likes) return 0; // likesがnullの場合は0を返す
    return likes.filter((like) => like.comment_id === comment_id).length
};


useEffect(() => {
  if (likes) {
    const count = countLikesByCommentId(comment_id);
    setLikeCount(count);

    if (user) {
      const userLike = likes.find(like => {
        if (like.user_id !== user.uid) {
          return false; // ユーザーIDが一致しない場合はfalseを返す
        }
  
        return like.comment_id === comment_id; //trueを返す
      });
      setUserLike(userLike || null);
    }
  }
}, [likes, user]);

  /*useEffect(() => {
    const conditions: { key: string, val: any }[] = [];
    Object.entries(sub_category).forEach(([key, val]) => {
      conditions.push({ key: key, val: val });
    });

    // Only proceed if flags is not null
    if (flags) {
      const count = countFlagsByCategoryAndConditions(category, conditions);
      setFlagCount(count);

      if (user) {
        const userFlag = flags.find(flag => 
          flag.user_id === user.uid && 
          flag.category === category && 
          conditions.every(condition => flag.sub_category[condition.key] === condition.val)
        );
        setUserFlag(userFlag || null);
      }
    }
  }, [category, sub_category, flags, user]);*/



  if(!isAnnivContents){
    return
  }
  return (
      user && (
        <div className="likes">
        {userLike ? (
          <button className="likes__btn likes__btn--remove" onClick={handleRemoveLike}><FaHeart />{likeCount !== null && <span className="likes__btn__count">{likeCount}</span>}<span className="likes__btn__text">×取り消す</span></button>
        ) : (
          <button className="likes__btn likes__btn--add" onClick={handlePushLikeBtn}><FaHeart />{likeCount !== null && likeCount !== 0 && <span className="likes__btn__count">{likeCount}</span>}<span className="likes__btn__text">いいねする</span></button>
        )}
        </div>
      )
  );
};

export default LikesComponent;
