// MISSION
export interface Mission {
  title: string;
  subTitle: string;
  text: string;
  description: string;
}

export const mission: Mission = {
  title:'MISSION',
  subTitle:'私たちの「存在意義」「目指す未来」',
  text:'めっちゃいい買い物を<br>たくさんしてもらう',
  description:`<p>「めっちゃいい買い物をたくさんしてもらう」<br class="isPc">
が我々Discoveryのミッション＝会社の生涯を賭して実現させていきたいこと、我々が目指すことです。
</p>
<p>
良いと思える商品、値打ちのあるものと出会った時、感動すると思います。<br>
いい買い物が増える、企業と生活者の出会いが増える、<br class="isPc">
世の中のお金の回りがよくなり、<br class="isPc">
経済が元気になって世の中の企業様が活発になる、働く人達も豊かになる。<br>
そんな世界が我々が今突き詰めようとしている仕事の先にあると信じています。
</p>
<p>
より良い知識、役立つ知識を顧客に伝え、顧客の商品やサービスを広く、効果的に、詳細に伝えて、<br class="isPc">
生活者に試してもらったり、買ってもらう事業、いわばデジタルの知識や技能を用いたマーケティングの支援事業です。
</p>
<p>
我々Discoveryの名前の由来はビタミンCを発見した<br class="isPc">
ハンガリーの生物学者セント＝ジェルジ・アルベルト氏の言葉の<br>
<br>
発見とは、誰もが見たことのあることをじっくり見据えた上で<br class="isPc">
誰一人として考えたことのないことを、考えてみることである。<br>
<br>
から名付けました。
</p>
<p>
昨今、デジタル（WEB）のマーケティングの仕事は、市場の発展によって、誰もが簡単に取り組めるようになりました。<br>
デジタル（WEB）に接続できる環境とやる気さえあれば誰だってはじめられる仕事です。<br>
誰もが取り組むことができることの見方を変え、創意工夫をすることで誰もが見つけられなかった、新たな「発見」が見つかる。<br>
WEBマーケティングの仕事の中で、「発見」をたくさんしていく<br class="isPc">
企業になりたい、追求したい、こだわりたい、熱中したい<br class="isPc">
というのが社名の由来です。
</p>
<p>
自分達の仕事に誇りと情熱、執念をもって取り組み、一人でも多くのひとに<br class="isPc">
「めっちゃいい買い物をたくさんしてもらう」社会を実現します。
</p>`
}