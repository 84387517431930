import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useAppContext } from '../../../AppContext';

interface AnimatedComponentProps {
  animation?: string;
  children: React.ReactNode;
}

const Animated: React.FC<AnimatedComponentProps> = ({ animation = "addClass", children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.matchMedia('(min-width: 1024px)').matches);
  const [isActive, setIsActive] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null); // デバウンス用タイマー

  const {
    isAutoZoomMode
  } = useAppContext();

  const debounce = (func: Function, wait: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return function(...args: any[]) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), wait);
    };
  }
  
  
  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    const handleResize = (e: MediaQueryListEvent) => {
      setIsDesktop(e.matches);
    };

    mediaQuery.addEventListener('change', handleResize);
    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  const switchScaleUpPropertyByDevice = () =>{
    if(!isDesktop){
      return(
        {
          initial: { scale: 0.8 },
          animate: { scale: 1 },
          transition: { duration: 0.5 }
        }
      )
    } else {
        return(
          {
            initial: { scale: 1 },
            animate: { scale: 1.2 },
            transition: { duration: 0.5 }
          }
        )
    }
  }
  

  const animations: { [key: string]: { initial: any, animate: any, transition: any } } = {

    addClass: {
      initial: { opacity: 1 },
      animate: { opacity: 1 },
      transition: { duration: 0 }
    },
    fadeUp: {
      initial: { opacity: 0.8, transform: 'translateY(-1em)' },
      animate: { opacity: 1, transform: 'translateY(0)' },
      transition: { duration: 0.5 }
    },
    fadeIn: {
      initial: { opacity: 0 },
      animate: { opacity: 1 },
      transition: { duration: 0.5 }
    },
    fadeOut: {
      initial: { opacity: 1 },
      animate: { opacity: 0 },
      transition: { duration: 0.5 }
    },
    scaleUp: switchScaleUpPropertyByDevice(),
    fontSizeUp: {
      initial: { fontSize:'1em'},
      animate: { fontSize:'1.2em'},
      transition: { duration: 0.5 }
    },
    scaleDown: {
      initial: { scale: 1 },
      animate: { scale: 0.8 },
      transition: { duration: 0.5 }
    },
    slideIn: {
      initial: { x: -100 },
      animate: { x: 0 },
      transition: { duration: 0.5 }
    },
    appear: {
      initial: { opacity: 0, scale: 0, transformOrigin: 'left' },
      animate: { opacity: 1, scale: 1 },
      transition: { duration: 0.5 }
    },
    // 他のアニメーションを追加
  };

  const animationProps = animations[animation];

  const handleHoverStart = () => {
    if (isDesktop) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current); // 前のデバウンスをクリア
      }
      debounceTimeout.current = setTimeout(() => {
        setIsActive(true);
      }, 100); // デバウンス時間を設定
    }
  };

  const handleHoverEnd = () => {
    if (isDesktop) {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current); // 前のデバウンスをクリア
      }
      debounceTimeout.current = setTimeout(() => {
        setIsActive(false);
      }, 100); // デバウンス時間を設定
    }
  };

  // const handleInView = debounce((entries: IntersectionObserverEntry[]) => {
  //   if (!isDesktop) {
  //     setIsActive(entries[0].isIntersecting);
  //   }
  // }, 100); // 100ms の遅延


  const handleInView = debounce((entries: IntersectionObserverEntry[]) => {
    if (!isDesktop) {
      if (entries[0].isIntersecting) {
        // ビューポートに入ったら isActive を true にするが、外れても false にはしない
        setIsActive(true);
      }
    }
  }, 100); // 100ms の遅延



  useEffect(() => {
    // sp/tablet時のみ
    const observer = new IntersectionObserver(handleInView,{ rootMargin: '-150px', threshold: 0});
    if (!isDesktop && ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [isDesktop,handleInView]);


  return (
    <>
    {isAutoZoomMode ? (
      <motion.div
      ref={ref}
      initial="initial"
      whileHover={isDesktop ? "animate" : undefined}
      whileInView={!isDesktop ? "animate" : undefined}
      variants={animationProps}
      className={`isAutoZoomMode ${isActive ? 'active' : ''}`}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      style={{ originX: 0, originY: 0.5 }}
      >
        {children}
      </motion.div>
    ) : (
      <motion.div
      ref={ref}
      className={"isNoZoomMode"}
      >
        {children}
        </motion.div>
      )}
    </>
  );
};

export default Animated;
