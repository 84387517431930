// VALUE
export interface Value {
  title: string;
  subTitle: string;
  values: {
    [key: number]: {
      number: string;
      value: string;
      description: string;
      actionAndStance: {
        [key: number]: {
          title: string;
          description: {
            [key: string]: {
              text:string
            }
          }
        }
      };
    };
  }
}

export const value:Value = {
    title:'VALUE',
    subTitle:'私たちらしい「あり方」・「価値観」として以下を掲げ、ミッションに向けて行動します。',
    values:{
      0:{
        number:'01', 
        value:'チームで仕事をする',
        description:`
        <p>我々は、顧客のビジネスを成功に導くことが最も重要であると考え、<br class="isPc">
        それにより生活者も豊かになる。<br>
        そして顧客の成功を実現するためには個人では無くチームで仕事をすべきである。<br>
        個人単位での仕事ではスピード、質に限界がある。
        </p>
        <p>
        <span>チームとして専門知識を発揮することにより、<br class="isPc">
        個人よりも何倍もの品質の仕事ができる。</span>
        </p>
        <p>
        個人では到達不可能な目標をも達成することが可能になり顧客を成功に導けると考える。<br>
        そのためにもチームで仕事をすることが不可欠だ。
        </p>
        <p>
        Discoveryのチームには大きく分けて部署単位、案件・プロジェクト単位がある。<br>
        部署単位では各自の専門性を高め、<br class="isPc">
        自らが保有していない知識やスキルがあれば積極的に教えを請い、<br class="isPc">
        時には教え与え、伝え広める。またそのための環境づくりも惜しんではいけない。
        </p>
        <p>
        <span>そうやって相互に研鑽し<br class="isPc">
        自らの能力水準を高め続けるべきである。</span>
        </p>
        <p>
        案件・プロジェクト単位では、<br class="isPc">
        自身の得意領域において責任を持って最高のパフォーマンスを発揮することと、<br class="isPc">
        各部署の役割と使命を明確化することが重要だ。<br>
        そして、案件に携わる各自が共通の目的を持って取り組むべきである。<br>
        それが個人ではなくチームで遂行することで重なり合い、<br class="isPc">
        顧客の成功が実現されると考える。
        </p>
        <p>
        したがって、Discoveryではチームで仕事をすることを求める。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'目標を理解する',
            description:{
              company:{text:`チームの目標はメンバー全員が認識し、理解する必要がある。<br>
              チームの目標を認識していない、理解が浅いのであれば、議論をする、<br class="isPc">
              あるいはメンバーに問いかけ、チームの目標を理解する。
              `},
              acountPlanning:{text:`自身の目標設定と、その目標達成のための施策や行動をセットで用意する。<br>
              また、案件進行においてクライアントの目標を理解しチームで共有し共通認識を持つ。
              `},
              mediaPlanning:{text:`常に今何しているかを考え行動する。<br>
              CPAを下げることとCV数を増やす施策はセットで行うべきである。
              `},
              creative:{text:`<span>【チームで品質のゴールを共有する】</span><br>
              案件のスタート時や理解しづらい修正依頼などに携わる各専門分野のメンバーと<br class="isPc">
              しっかりミーティングでコミュニケーションをとり、求められている品質を共有してスタートする。
              `},
              corporate:{text:`会社目標、部・グループ・チーム目標を理解し、<br class="isPc">
              自身の目標がどのようにつながっているのかを正確に把握し、説明できるようにする。
              `}
            }
          },
          1:{
            title:'役割を理解する',
            description:{
              company:{text:`チームの目標と同様に、チーム内での個人の役割も認識し、理解する必要がある。<br>
              個人の役割が無ければ、役割を自ら考え提案をする。<br>
              もしくは議論し、個人の役割を決定する。<br>
              与えられた役割を全うし、最高のパフォーマンスを発揮することで、チームの目標が達成に近づく。
              `},
              acountPlanning:{text:`チーム/プロジェクト/個別の目標達成のために、<br class="isPc">
              自分はもちろん各部署やメンバーに担ってもらいたい役割を明確に示し、<br class="isPc">
              チーム/プロジェクト内で合意をとった上で進行する。
              `},
              mediaPlanning:{text:`業務の意図を理解することがミスや事故を起こさないコツの1つである。<br>
              点で捉えると単純に見える業務も、実は大きな目的につながっている。<br>
              物事を曖昧で進めてしまうと、ミスや事故を引き起こしてしまう。<br>
              その為、周りに理解できるまで問いかけする。<br>
              意図を理解していれば、目的に繋がる仕事ができる。
              `},
              creative:{text:`<span>【お互いの役割を見える化する】</span><br>
              キックオフやアサインの際に、自ら業務領域を宣言または確認し、<br class="isPc">
              全員に対して合意を取り記録に残す。それによってメンバーの責任の所在を明らかにするとともに、<br class="isPc">
              お互いに信頼をもって業務にあたることができる。`},
              corporate:{text:`チーム目標を達成するために自身の担当領域について役割を理解し、全うする。<br>
              また、チーム目標達成に向けてチームメンバーの役割を理解し、<br class="isPc">
              アドバイスやコミュニケーションを行う。
              `}
            }
          },
          2:{
            title:'依頼には意図を添える',
            description:{
              company:{text:`依頼や伝達が発生した際は、業務内容のみでは無くその先の意図を適切に伝える。
              `},
              acountPlanning:{text:`受け手にとって意図が理解しやすいよう情報を整理した上で伝える。<br>
              制作/運用部署が見えていない背景と目的を添えて依頼し、<br class="isPc">
              最終目標・アウトプットイメージの共有を行う。
              `},
              mediaPlanning:{text:`施策と仮説は常にセットである。<br>
              施策には意図を持ち、「なぜ行うのか」の仮説と自分の意思を持つ。
              `},
              creative:{text:`<span>【制作業務のスタートはまず情報整理から】</span><br>
              制作業務にとりかかる下準備として<br>
              ・目的／目標　・現状の課題／問題点　・仕様（サイズ等）／必要物（データ等）　・納期<br>
              を可視化して依頼者との共通認識を持った上でスタートさせる。`},
              corporate:{text:`依頼や伝達が発生した際は、業務内容のみではなく、<br class="isPc">
              その先の意図を相手が理解できるように説明を行う。
              `}
            }
          },
          3:{
            title:'工程を遵守する',
            description:{
              company:{text:`定めた工程を守ることで目標達成までの速度がより上がる。
              `},
              acountPlanning:{text:`既存ルールの順守はもちろん、より良くする視点をもって活動する。 <br>
              工程・納期がどうしても遵守できない場合は、代案を用意し調整を進める。 <br>
              工程を守れないメンバーがいた場合には、注意などの対応を行う。
              `},
              mediaPlanning:{text:`曖昧なことは放置せず、不明点をつぶしてから進める。<br>
              不明な点や疑問に感じた点は、答えが明確に出るまでとことん調べる。<br>
              そして周りに説明できるように、内容を整理する。
              `},
              creative:{text:`<span>【不測の事態には、即座に上司に相談する】</span><br>
              問題が起こる前に必ず相談を行う。<br>
              急ぎの依頼が来る、自分の技量不足などで、もともと想定していた納期・品質を<br class="isPc">
              守れなくなりそうな時は、業務の優先順位を上司やフロントに立つ担当者と相談する。`},
              corporate:{text:`業務フローやマニュアルに基づいて正確に処理を行い、<br class="isPc">
              マニュアル外のイレギュラー事項についても対応できるようにする。<br>
              また、それを業務フローやマニュアルに反映する。<br>
              <br>
              業務進行の遅れ等が発生した場合、<br class="isPc">
              その原因と対処法についてチームと連携しながら対応する。
              `}
            }
          },
          4:{
            title:'積極的に教えを請い、伝え広める',
            description:{
              company:{text:`チームの目標を達成するためには知識やスキルを高め続ける必要がある。<br>
              自ら学び実践経験を積むのはもちろんのこと、時には同僚、上司に教えを請い耳を傾ける。<br>
              そうやって取得した知識やスキル・実戦経験を個人のみで留めるのではなく、<br class="isPc">
              勉強会等の共有の機会を自発的に設け伝え広める。
              `},
              acountPlanning:{text:`解決したい課題について、周囲に聞いたり学習制度を活用して情報収集し、<br class="isPc">
              チームへ有益な情報として伝える。<br>
              また、一時的な共有だけでなく、誰からでも確認できるように記録・蓄積する。
              `},
              mediaPlanning:{text:`まず自分で調べる。10分調べて試してみて分からなければ理解するために聞く。<br>
              分からない者同士で傷の舐め合いをしてはならない。<br>
              まずはトレーナーに教えを請い、トレーナー不在時はサブリーダーかリーダーに聞く。<br>
              そして教えられた内容をメモや議事録に残し、チーム内で展開する。
              `},
              creative:{text:`<span>【自らの意思を持ちチームで問題解決する】</span><br>
              問題解決をするために、周囲に積極的にアドバイスを求めること。<br>
              アドバイスを求める時は、ゴールと達成するための手段を自分なりに考えた上で相談する。<br>
              ゴールや手段が分からない時も、自分がどう考えたかを伝えた上で、相談する。`},
              corporate:{text:`知らない用語や知識等が出てきた際は、<br class="isPc">
              正しく理解・解消するための行動をとる。<br>
              さらに、チームメンバーに共有しチームの業務フローに反映する。
              `}
            }
          },
          5:{
            title:'チームで品質を確認する',
            description:{
              company:{text:`メンバー間で、互いの品質や、高い水準で仕事ができているかを確認し合う。
              `},
              acountPlanning:{text:`得意先に対する納品物の品質チェックを行う。<br>
              また、目標と現状の乖離を認識し、足りていないことを<br class="isPc">
              言語化しチーム内で共通認識として浸透させていく。<br>
              その対策について報告・相談しチームで共有する。
              `},
              mediaPlanning:{text:`目線を合わせるために対話し、品質を確認することが成果の最大化につながる。<br>
              確認工程を二度手間と考えるのではなく成果二倍になるための工程であると考える。<br>
              変革を恐れず、品質向上のためには既存工程も継続してアップデートしていく。
              `},
              creative:{text:`<span>【関わっている仕事は専門外の領域であっても自分事として捉える】</span><br>
              自分がゴールが見えていない案件をその状態で他人に渡さない。自分なりの着地点を必ず持って渡す。
              `},
              corporate:{text:`自身の担当業務について、<br class="isPc">
              チームメンバーからのフィードバックを自ら積極的に求め、品質を維持・向上させる。<br>
              周囲の業務にも関心を持ち、リマインドやフィードバックを積極的に行う。
              `}
            }
          }
        }
      },
      1:{
        number:'02', 
        value:'助け合いの精神を持つ',
        description:`
        <p>
        我々の考える助け合いの精神とは、<br class="isPc">
        一人で解決できない問題、課題を協力して解決へ導く姿勢である。
        </p>
        <p>
        <span>また自らも助け、<br class="isPc">
        協力を乞うことに恥や躊躇を感じる必要はない。</span><br>
        助け、協力を乞わないことによる結果は、怠慢である。
        </p>
        <p>
        我々の目指す姿は会社の目標を全員が把握し、<br class="isPc">
        その目標を達成すること一点に集中して全員が行動をとっている状態である。<br>
        そのためには、自分の役割の範囲だけではなく、<br class="isPc">
        自身のチームや周囲のメンバーを助ける精神を全員で持つことが必要である。<br>
        この助け合いの精神が会社の成功と顧客の成功につながると考える。
        </p>
        <p>
        <span>我々は目標達成のため、<br class="isPc">
        助けを乞うことを恥とせず助けを求めることができ、<br class="isPc">
        メンバー・役職・立場関係なく、<br class="isPc">
        手を差し伸べることができる人間を称賛する。</span>
        </p>
        `,
        actionAndStance:{
          0:{
            title:'助けられる側の姿勢',
            description:{
              company:{text:`常に計画を立てて行動し計画段階で<br class="isPc">
              自分のタスクが遂行できるできないを判断し協力を乞うことをする。<br>
              また報告連絡相談を徹底しトラブルの発生、事態の悪化を未然に防ぐことを意識する。<br>
              それでも助けが必要になった時には、すぐに助けを求め、助けを求める際は相手への敬意を持ち、<br class="isPc">
              相手の時間を借りている意識を持ち、借りた時間を返すことを意識する。<br>
              そして助けてもらった相手に対して必ず感謝の気持ちを伝える。
              `},
              acountPlanning:{text:`常に計画を立てた上で行動し、その進捗を上長に対し報連相を行う。<br>
              その中で、計画通り進まないということが分かった時点で部署や立場を問わず率先して相談する。<br>
              また、助けられた事柄は改善し、同じ失敗や相談をしないための取り組みを行う。
              `},
              mediaPlanning:{text:`他者の力も借りる。<br>
              他社、他部署の役割や得意分野を理解する。<br>
              専門の人に協力を仰ぎ、グルーヴ感を出しながら目標を達成する。
              `},
              creative:{text:`<span>【助けられる状態を常にたもつこと】</span><br>
              自分の持つ情報やデータを”他の人が把握できる状態で共有する”ことで、<br class="isPc">
              他の人から助けてもらえる環境をつくることができる。<br>
              自分だけが分かる状態で仕事を行うことは、大きな問題につながる、リスクのある進め方である。<br>
              <br>
              <span>【助けが必要な事項を明確に】</span><br>
              ただ助けを請うのではなく、<br class="isPc">
              「技術・知識・発想が足りないのか」「リソース（主に時間）が足りないのか」など<br class="isPc">
              自分が何に困っているのかを相手に正確に分かるように伝える。
              `},
              corporate:{text:`自身の業務のタスク管理と優先順位付けを行い、<br class="isPc">
              知識・経験・能力が足りずに助けを求める際には、相手の時間を借りているという意識を持ち、<br class="isPc">
              どの部分を助けてほしいのかを明確に伝える。
              `}
            }
          },
          1:{
            title:'助ける側の姿勢',
            description:{
              company:{text:`トラブルが起こる可能性があると気付いたときはすぐに手を差し伸べ、<br class="isPc">
              いつか自分が助けてもらう事になるという気持ちを常に持ち、<br class="isPc">
              役職・立場関係なく困っているメンバーがいれば手を差し伸べる。
              `},
              acountPlanning:{text:`普段から相談しやすい振る舞い、進行を心がけ、困っている人がいればすぐに手を差し伸べる。<br>
              チーム/プロジェクトメンバーのミスに対して叱責するのではなく、<br class="isPc">
              まずは手を差し伸べて再発防止を働きかけるようにする。<br>
              何度も助ける必要がないように、同じ質問や要請が発生しないようなフローを作る。
              `},
              mediaPlanning:{text:`自分の担当案件でなくても、自分にToがついていないチャットでも、<br class="isPc">
              困っている人がいれば、自分がわかること、<br class="isPc">
              できることは察知して積極的に手を差し伸べる。
              `},
              creative:{text:`<span>【助けを求められるのは名誉なことである】</span><br>
              助けを求められるということは、この人なら解決できると認識してもらっているということ。<br>
              チームと自己成長の機会ととらえ、可能な限り応えてあげる姿勢をもつ。<br>
              また、全て応えることが難しい場合でも、自分が思いつく解決方法を与えてあげる。
              `},
              corporate:{text:`チーム・組織の一員であるという意識を持ち、全体的なスケジュールや業務を把握する。<br>
              チームメンバーの遅れや問題に対しては、自ら声をかけて助けるようにする。
              `}
            }
          }
        }
      },
      2:{
        number:'03', 
        value:`ルールを守り、<br>誠実に正しいことをする`,
        description:`
        <p>
        我々は常に、顧客ひいてはサービスが<br class="isPc">
        最終的に提供されるお客様に対し誠実であらねばならない。<br>
        <span>誠実であるということはどういうことか、<br class="isPc">
        我々は「ルールを守る」「道徳・倫理観を持つ」と定義する。</span>
        </p>
        <p>
        なぜ「ルールを守る」「道徳・倫理観を持つ」必要があるのか？<br>
        スポーツの試合に例えてみよう。<br>
        あらゆるスポーツは一定のルール、<br class="isPc">
        道徳・倫理観（試合とは関係のないところで他人を貶めるような行為）のもと<br class="isPc">
        お互いに競い合うことで成立するものである。<br>
        ルール、道徳・倫理観がなければ己の利益のために自己中心的な行動を起こし、<br class="isPc">
        スポーツと呼べる代物ではなくなってしまう。<br>
        それのどこに世間は感動し対価を支払うだろうか。
        </p>
        <p>
        それは、我々のビジネスにおいても同じことが言える。<br>
        ルール（法律や規則など）やビジネスにおける倫理観や道徳観（嘘を付く、<br class="isPc">
        他社を貶めようとする行為など）を守った上で成果を出すことが必要である。<br>
        なかには抜け道を使い、ルールを逸脱して仕事をしている人間もいるが、<br class="isPc">
        必ずそういった人間は顧客からの信用を失い仕事を失ってしまう。<br>
        <span>常に自分自身、<br class="isPc">
        周りを見渡して道を外れていないか確認して欲しい。</span><br>
        </p>
        <p>
        よって、Discoveryで働く人間には、<br class="isPc">
        ルールを守り、誠実で正しくあることを前提として求める。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'法令順守',
            description:{
              company:{text:`法律を守って仕事をする。法律を守らなければ何が起きるかを考えよう。<br>
              会社に迷惑がかかるのはもちろん、取引先や自分の家族にも迷惑がかかる。<br>
              「自分だけ良ければ良い」「知らなかった」では済まされないため、法律を理解するのはもちろん、<br class="isPc">
              心配であれば周りに相談しながら仕事をすべきだ。          
              `},
              acountPlanning:{text:`制作において、業務にまつわる法令が遵守できているか、得意先提出前のチェックを行う。<br>
              また、得意先の表現可能な範囲を理解し指示・進行できているか確認する。
              `},
              mediaPlanning:{text:`法令改正の情報を取得できずに、結果として法令を違反し、「知らなかった」では済まない。<br>
              法令に関してのアンテナをを張り、その内容を理解する。
              `},
              creative:{text:`<span>【クライアントを守る表現ルール遵守を】</span><br>
              制作する際には薬機法／景品表示法などの法令に則った広告をつくり、<br class="isPc">
              クライアントのブランド毀損や法令違反のないようにわかる人ともに確認を徹底する。
              `},
              corporate:{text:`関係法令・規則に対する理解を深め、遵守するために、<br class="isPc">
              専門家に確認・連携を取ることができる。
              `}
            }
          },
          1:{
            title:'最新の法令のアップデートを怠らない',
            description:{
              company:{text:`我々の業界は日進月歩で技術が進化し、その技術を取り巻く法令に関しても常に変化をしている。<br>
              以前は良かったことも今となっては違反していることもあるため、<br class="isPc">
              常に正しい判断ができるように情報をアップデートしていなければならない。
              `},
              acountPlanning:{text:`法令改正に関連する情報と影響をキャッチアップし、<br class="isPc">
              得意先・チーム・社内へ共有していく。
              `},
              mediaPlanning:{text:`広告表現において、薬機法、景品表示法、その他関連法令を遵守する。<br>
              法令というルールを熟知し、ルールを制することが、顧客の成果に繋がる。<br>
              まだ理解が浅く、法令遵守が不安な場合は必ず周りに協力を仰ぐ。
              `},
              creative:{text:`<span>【表現のルール遵守はクライアントのため】</span><br>
              クライアントのレギュレーションを理解・把握して制作することにより、<br class="isPc">
              クライアントの信頼が得られて次の仕事につながる。<br>
              担当者が変更した時や前回の依頼から期間が空いた時でも<br class="isPc">
              クライアントに同じ指摘をさせることのないよう、新しい担当者におさらいを必ず行う。
              `},
              corporate:{text:`自身の業務に関係する法令について、<br class="isPc">
              アップデートするための情報源を持ち、自身の業務に反映していく。
              `}
            }
          },
          2:{
            title:'道徳／倫理観を持って仕事をする',
            description:{
              company:{text:`「嘘を付いたり」「ミスを隠したり」不正を働くようなことをせずに<br>
              胸を張って仕事をすることを求める。<br>
              また周りがそのような行為をしていた際にも<br class="isPc">
              毅然とした態度で注意ができる人間であらねばならない。<br>
              それが己の人間性を高めることに繋がる。                        
              `},
              acountPlanning:{text:`納期やタスク等、約束は必ず守る。<br>
              約束が守れないと分かったときやミスが発覚した際には、隠さずすぐに相談・報告を行う。<br>
              周りに相談・報告ができていないメンバーがいた場合は、注意などの対応を行う。
              `},
              mediaPlanning:{text:`工程を遵守できなかった場合、配信事故が発生した場合、<br class="isPc">
              事故には至らずともミスに気づいた場合、報告連絡相談を行う。<br>
              自身のみならず、他人が同一の状況で報告連絡相談を怠っていた際には、指摘し促す。
              `},
              creative:{text:`<span>【問題は必ず表面化するもの】</span><br>
              問題を含んだ制作物・そのやり取りも、発生した直後に気づいているのは自分だけかもしれない。<br>
              しかし、見て見ぬふりは必ず表面化し明らかになってしまう。<br>
              問題があると感じたら、たとえ期日の直前であったとしても、<br>
              正直に打ち明け対応策を相談する勇気を持つ。
              `},
              corporate:{text:`個人としての発信も会社としての発信と捉えられる部門であることを理解し、<br class="isPc">
              適切に発言・行動する。
              `}
            }
          },
          3:{
            title:'素直なこころ',
            description:{
              company:{text:`もし仮に嘘をついてしまったり、何か不正を働いてしまった場合は正直に申し出て欲しい。<br>
              それ自体も問題ではあるが、それを隠していたことのほうがもっと問題である。<br>
              怒られることを恐れて最悪の事態にならないようにしなければならない。                       
              `},
              acountPlanning:{text:`誤りやミスががあった場合は素直に受け止め、行動を改め、再発防止の取り組みを行う。
              `},
              mediaPlanning:{text:`自身の不安に思うことやミスを隠さず受け入れ、申し出てほしい。<br>
              ミスが最短で判明できればすぐに立て直せる。
              `},
              creative:{text:`<span>【自らを改める勇気を持つ】</span><br>
              仕事にミスや間違いはつきもの。<br>
              起こってしまった事実を受け入れ、素直に態度や行動を改めることが成長の第一歩となる。
              `},
              corporate:{text:`グレードの上下に関係なく、仲間の指摘・意見や失敗も自身の成長の糧とし、<br class="isPc">
              まっすぐにゴールを目指す。
              `}
            }
          }
        }
      },
      3:{
        number:'04', 
        value:`進歩のために、<br>挑戦は楽しむもの`,
        description:`
        <p>
        <span>挑戦とは、個人やチームが「初めて取り組むこと」、<br class="isPc">
        「以前うまくいかなかったこと」、<br class="isPc">
        「これまでの結果を超えること」、<br class="isPc">
        これらに対して前向きに取り組む姿勢のことである。</span><br>
        この姿勢がなければ成果を得ることはなく、停滞・衰退をするのみだ。
        </p>
        <p>
        Discoveryは挑戦する人間を称賛、応援し、ともに挑戦する。<br>
        <span>挑戦した結果、昨日の自分より進歩することができるのだ。<br>
        そして、ともに挑戦する仲間に対して、<br class="isPc">
        敬意と思いやりをもてるのである。</span><br>
        これを楽しむのがDiscoveryだ。
        </p>
        <p>
        ただし、自分勝手な内容や無闇な行いを挑戦と呼ぶことはできない。<br>
        <span>「明確な目的」、「成功させるための計画」、<br class="isPc">
        「投げ出さない執念」を必ず持つこと。</span><br>
        それでもうまくいかなかった時、それは次に挑戦するための過程であり、糧となる。<br>
        そんな失敗に対しての非難や、次の挑戦への妨げはしてはならず、<br class="isPc">
        仲間としてサポートすることが当然である。<br>
        最終的に到達することができれば、それはもはや失敗ではなくなるのだ。
        </p>
        <p>
        失敗を恐れず、挑戦を楽しむ姿勢がDiscoveryで働くうえで欠かせない要素である。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'意義のある挑戦をする',
            description:{
              company:{text:`自分やチームにとって意義のある挑戦にするためには、<br class="isPc">
              何を得てどういう状態になっているかという目的を決めなければならない。<br>
              そして綿密かつ現実的な計画を組み、徹底的に遂行する執念をもって取り組む。
                        
              `},
              acountPlanning:{text:`目標設定する際には状態目標および定量的な設定を行う。<br>
              また、それを実現するための行動計画をセットで準備する。
              `},
              mediaPlanning:{text:`自主性と積極性を持つ。<br>
              案件によってはサポートがつく場合もあるがあくまで案件担当は自分であるということを自覚する。<br>
              成果が出ても出なくてもすべて自分で決めて行った施策の結果である。<br>
              クライアントの大切な予算をいただいているということを意識し、自主性と積極性を発揮する
              `},
              creative:{text:`<span>【己の壁を超える】</span><br>
              制作物の表現に対して必要な手法・技法はまず一度挑戦する。<br>
              その行動がアウトプットとして形になれば必ず己の糧となる。<br>
              もし形にならなかったとしても次の挑戦のヒントを得られる。<br>
              己の壁は簡単に越えられるものではないが挑戦しない者に成長は訪れない。
              `},
              corporate:{text:`会社に寄与できる挑戦かどうかを考え、<br class="isPc">
              具体的な計画を立て行動する。
              `}
            }
          },
          1:{
            title:'成功はもちろん、失敗も必ず次に活かす',
            description:{
              company:{text:`挑戦によって得られた結果の要因分析を行い、<br class="isPc">
              改善案として共有し、自身と仲間の糧にする。            
              `},
              acountPlanning:{text:`成功・失敗した要因を分析・言語化し記録していく。<br class="isPc">
              また、それをチームや社内に共有する。
              `},
              mediaPlanning:{text:`狙いを持っているか？<br>
              狙いの無い失敗は、過失である。<br>
              狙いを持っていれば、振り返ることができる。<br>
              失敗は成功の基である。
              `},
              creative:{text:`<span>【挑戦は学びの機会と考える】</span><br>
              挑戦したことは、結果の成功・失敗に関わらず必ず自分の糧となる。<br>
              また、挑戦したことに対して“なぜその結果になったのか”、<br class="isPc">
              その要因を制作部の知識・ノウハウ・事例として共有・周知する。
              `},
              corporate:{text:`挑戦によって得られた結果の要因分析を行い、<br class="isPc">
              改善案として共有し、自身と仲間の糧にする。
              `}
            }
          },
          2:{
            title:'知らない、わからないことを挑戦しない理由にすべきではない',
            description:{
              company:{text:`方法がわからない場合には必ず調べ、類推し、アドバイスを求める。                        
              `},
              acountPlanning:{text:`わからないこと知らないことに直面した際は、<br class="isPc">
              わからないことを特定するために調べたり類推し、アドバイスを求めることで<br class="isPc">
              自ら情報を取りに行くアクションにつなげる。<br>
              難易度が高い挑戦においても、達成できる可能性について検討し周囲に意見を請う。
              `},
              mediaPlanning:{text:`知らない、わからないことを成功のための「チャンス」ととらえる。<br>
              依頼された業務や経験していない作業に対して、「無理かもしれない」と考えがちになってしまう。<br>
              過去経験した先輩もいるためどう乗り越えてきたか、<br class="isPc">
              どう考えどう行動すべきかを思考をめぐらせ自身の経験、知見として力にする。
              
              `},
              creative:{text:`<span>【常に相手（クライアント）にとって最適化を探る】</span><br>
              「できない」という考えからスタートするのではなく「何ができる」かを考え最善の可能性を探る。<br>
              固定観念、バイアスがかかった1つの答えに縛られないようにする。
              `},
              corporate:{text:`わからない、処理したことがない業務については、<br class="isPc">
              「どこに・誰に・何を聞けばいいか？」を考え、行動に移した上で業務に取り組む。
              `}
            }
          },
          3:{
            title:'平常の業務をおろそかにしてはならない',
            description:{
              company:{text:`挑戦には普段以上に労力をかけるものの、<br class="isPc">
              挑戦そのものや業務全体を遂行できるように優先順位や計画調整を検討、相談する。
                                     
              `},
              acountPlanning:{text:`注力案件、注力販路へのてこ入れなど挑戦を要する業務において、<br class="isPc">
              並行して進める平常業務の優先順位を正しく付けてエラーなく遂行する。
              `},
              mediaPlanning:{text:`数字の変動に敏感であれ。<br>
              数字の変動には必ず要因がある。数字の変動を速やかに察知し、<br class="isPc">
              「記録」している運用ログを元にまずは内的要因、次に外的要因、の順で調査する。<br>
              数字だけでは見逃すこともあるため、グラフ化など変動を可視化する。
              `},
              creative:{text:`<span>【挑戦は失敗をした時のリスクヘッジをセットで行う】</span><br>
              計画的に行動していても、挑戦に失敗はつきものである。<br>
              その失敗が自分一人で解決できない場合も考慮して<br class="isPc">
              計画をチーム・上司に共有することも、挑戦の一部であると考える。
              `},
              corporate:{text:`挑戦そのものや業務全体の遂行のために、自身でミスのチェック機能を持つ。<br>
              平常業務の品質を保ちながら、特に重要な業務についても安定的に取り組む。
              `}
            }
          }
        }
      },
      4:{
        number:'05', 
        value:`専門性を高める`,
        description:`
        <p>
        「専門性」とは、特定の分野における高度な知識・ノウハウ・実績を意味する。<br>
        要するに「他者より知っている・できる・経験してる」状態のことを指す。
        </p>
        <p>
        <span>我々が扱っている多くの商品は、<br class="isPc">
        我々でなくても取り扱うことができるものである。</span>
        </p>
        <p>
        <span>市場で選ばれ続けるためにはその理由が必要で、<br class="isPc">
        専門性が高いことは大きな強みとなる。</span>
        </p>
        <p>
        1人1人が自身とDiscoveryと顧客にとって必要な知識・情報は何かを考え、<br class="isPc">
        その考えに沿い、自身の得意分野を磨いていく。<br>
        そういったメンバーが集まることで専門性を持った強い集団となる。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'情報の収集と共有を積極的に行う',
            description:{
              company:{text:`自身の職務における商品の基本的な情報はもちろん、最新情報や成功事例を常に収集・共有する。          
              `},
              acountPlanning:{text:`自身の課題に対して、足りない情報を理解したうえで収集できるようにする。<br>
              さらに、上長やチームに対して情報の活用例や、<br class="isPc">
              営業としての情報の活かし方を含めて共有を行う。
              `},
              mediaPlanning:{text:`自身の担当商品のリサーチを怠ってはならない。<br>
              ユーザー視点に立ち、商品理解を深めなければならない。<br>
              また、情報の鮮度には価値がある。<br>
              最新の情報収集無くして、価値のある成果を出すことはできない。<br>
              得た情報は運用全体チャットで即座に共有する。
              `},
              creative:{text:`<span>【ノウハウ知識は伝えてこそ価値となる】</span><br>
              成功事例・技術・ノウハウ・知識は伝えてこそ価値を発揮する。<br>
              周りに一人でも知らない人がいたら、その情報共有は成功といえる。積極的に共有する。<br>
              そして、共有してくれた人には、感謝・称賛する。
              `},
              corporate:{text:`最新の情報収集のための習慣を備え、社内へ情報共有するとともに、<br class="isPc">
              自身・チームの業務に具体的に活かせるようにする。
              `}
            }
          },
          1:{
            title:'積極的な対話・意見交換を',
            description:{
              company:{text:`クライアントの商材・競合・業界への理解を深めるため、自身での情報収集だけでなく、<br class="isPc">
              クライアントや社内メンバーとの対話により知識・情報を増やしていく。
                          
              `},
              acountPlanning:{text:`自身や案件の課題に対して、<br class="isPc">
              チームや部署問わず積極的に意見を述べたり、意見を請う。
              `},
              mediaPlanning:{text:`自分の意志を持ち、発言し行動する。<br>
              クライアント、社内に関わらず全員が同じ目的のために業務に携わっている。<br>
              議論の場で発言しない者、議論に事前準備をせず参加する者は、立ち去れ。
              
              `},
              creative:{text:`<span>【組織の発展につながる議論をする】</span><br>
              自分の良いと思った仕組みが必ずしも全体にとって良い仕組みであるとは限らない。<br>
              他の人にとっても役に立つことだろうか？と考えた上で議論や提案を行えば、<br class="isPc">
              その仕組みは真に価値のあるものとなる。
              `},
              corporate:{text:`他部門の業務を理解するために積極的にコミュニケーションをとり、<br class="isPc">
              お互いの事情を踏まえて、業務が円滑に進められるよう意見交換を行う。
              `}
            }
          },
          2:{
            title:'自身の得意分野を見つける・磨く',
            description:{
              company:{text:`自身がNo.1であるという知識・ノウハウを保有することで、社内および市場での価値を高める。<br>
              そのために、必要とされる知識を見定め、<br class="isPc">
              ただ知識を貯めこむだけでなくアウトプットを想定し積極的に学習する。
                                      
              `},
              acountPlanning:{text:`得意分野を持つために上長にアドバイスを求める。<br>
              その分野のスキルを習得するためのアクションを行う。
              `},
              mediaPlanning:{text:`自分が最大限貢献できる領域を作る。<br>
              誰でも扱うことのできる商品であるがゆえに「自分だからこそ」を発揮できる強みをもつべきである。<br>
              決して驕らず謙虚に自信を持ち磨き続け、知見として溜めることで全員の専門性が高まり、<br class="isPc">
              一人では出せないような良い結果が生まれる。
              
              `},
              creative:{text:`<span>【長所を伸ばすと自然と弱点も弱点ではなくなる】</span><br>
              弱点をすぐに成長させる事は難しい。それよりも自分の能力を俯瞰で見つめ、<br class="isPc">
              最大限の武器を磨き続ける事を薦める。<br>
              自分の能力を伸ばし続ける事は、プロフェッショナルな集団の中で<br class="isPc">
              部内のパフォーマンスも上がる事を意味する。
              `},
              corporate:{text:`目指すキャリアや習得したいスキルを具体的に描き、<br class="isPc">
              自身の専門分野における情報を積極的に発信を行う。
              `}
            }
          },
          3:{
            title:'学習制度を活用せよ',
            description:{
              company:{text:`著書やセミナーなど、知識習得に適した手段がある場合は活用し、効率的な学習を行う。                       
              `},
              acountPlanning:{text:`自分の課題に対して、不足している知識を学習する方法を考え、<br class="isPc">
              読書以外にも様々な方法で学習していく。<br>
              また、そこで得た学びをアウトプットする場を設ける。
              `},
              mediaPlanning:{text:`今までブチ当たったことのない課題に直面した際、<br class="isPc">
              自身の経験のみに頼るのではなく、<br class="isPc">
              学習制度を活用し、先人たちの知恵、すなわち書籍に頼るべし。
              `},
              creative:{text:`<span>【自他共に高め合える学びの場を作る】</span><br>
              部署にとって独自性・特異性がある自身の能力について、<br class="isPc">
              勉強会・講習会を開く機会を作り、周囲の人が参加できる環境を作る。<br>
              それをメンバーは貪欲に活用して知見・能力を習得する。
              `},
              corporate:{text:`自身のキャリアロードマップに基づいた課題を自ら設定し、<br class="isPc">
              レポート提出または成果を出す。
              `}
            }
          }
        }
      },
      5:{
        number:'06', 
        value:`積極的に、思い切って議論を`,
        description:`
        <p>
        議論とは目的を達成するために、意見を交換し、研鑽することである。
        </p>
        <p>
        明確な正解がない、決まった方法がない、Discoveryではこのような仕事がほとんどだ。<br>
        そういった仕事をする上では一人の思考で完結させず、<br class="isPc">
        周囲のメンバーと議論してより信頼できる答えを出す方が賢明である。
        </p>
        <p>
        自信を持っている答えでも、思考が及んでいない部分が必ずある。<br>
        議論によってそれを埋めることができ、より良い答えになる。<br>
        また、まったく違う考え方の新たな答えを発見することもある。
        </p>
        <p>
        <span>常に思考をめぐらせ、積極的に意見をぶつけること。</span><br>
        相手の意見に同意できない場合は、否定だけでなく代案を出すこと。<br>
        目的はこれから出す答えをより確かにし、成功を共に勝ち取るためだ。
        </p>
        <p>
        議論においては、常に意見し、積極的に発言すること。<br>
        Discoveryの仕事において、積極的に、思い切って議論することは欠かせない。<br>
        意見を持たず、議論をせずに言われたことだけをこなす者をDiscoveryは必要としていない。<br>
        <span>時には議論により摩擦が生じることもあるかもしれないが、恐れることはない。<br>
        議論は争いではなく協力である。</span><br>
        相手の意見に同意できない場合は、否定だけでなく代案を出すこと。<br>
        目的はこれから出す答えをより確かにし、成功を共に勝ち取るためだ。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'目的を持った議論を',
            description:{
              company:{text:`何事にも目的を持った議論が必要である。<br>
              意思決定や施策等のアクションを見据えた議論でなければならない。
                        
              `},
              acountPlanning:{text:`アジェンダや事前準備、議事録の記載を徹底する。<br>
              また議論のゴールイメージを持ち、自らの意思や施策のための自分なりの考えを示しながら<br class="isPc">
              ディスカッションを行う。
              
              `},
              mediaPlanning:{text:`指標と数字で語る。<br>
              実績についての対話や報告は、指標および数字で行う。
              
              `},
              creative:{text:`<span>【会議の予習と復習を行う】</span><br>
              案件や制作物の会議や打ち合わせにおいて、必ずアジェンダを設定または確認し、<br class="isPc">
              事前の資料に目を通したり発表内容の確認などの準備をして臨む。<br>
              決まったことはタスクとスケジュールに落とし込み参加者に共有する。
              
              `},
              corporate:{text:`議論の目的を果たせるように、必要な情報を事前に把握し、<br class="isPc">
              自分としての考え・方向性を話す。
              
              `}
            }
          },
          1:{
            title:'立場を問わず、常に意見を出し合う姿勢',
            description:{
              company:{text:`議論において意見を持たない、発言しない者をDiscoveryは求めない。<br>
              議論をするのに立場は関係ない。<br>
              意見を持って議論に臨み、そして自らの意見を伝えなければならない。
                          
              `},
              acountPlanning:{text:`自身の案件に対して自分の意思・意見をもって発言・進行を行う。<br>
              また、部署間を超えて、課題解決のために積極的に発言する。
              
              `},
              mediaPlanning:{text:`自施策起点の対話ではなく、問題・課題起点で対話をする。<br>
              施策から対話をするのではなく、まずは問題を把握し、<br class="isPc">
              次に課題を抽出し、その課題に対する施策といった順序で対話をする。
              
              `},
              creative:{text:`<span>【自分の意見を素直に伝える】</span><br>
              制作物のフィードバックの際に自分が思った事、<br class="isPc">
              感じた事は立場を関係なく素直に意見を伝えるようにする。<br>
              意見をぶつけ合う事でフィードバックをする者、される者<br class="isPc">
              お互いに新たな気づきが生まれ、それが制作物のブラッシュアップにつながる。
              
              `},
              corporate:{text:`課題解決に向けた建設的な考え・意見を出し、<br class="isPc">
              なぜその考え・意見になったのかの背景を説明できる。
              
              `}
            }
          },
          2:{
            title:'聞くときは真剣に',
            description:{
              company:{text:`まずは相手の意見を冷静に最後まで聞くべきだ。<br>
              また話を聞くときには先入観を持ってはならない。
                                      
              `},
              acountPlanning:{text:`自分と異なる意見だったとしても、意見してくれたことを尊重し、<br class="isPc">
              先入観を持たずにまずは最後まで話を聞き、理解しようと心がける。<br>
              そのうえで、理解できないポイントについては、意見者に対して質問や確認を行う。
              
              `},
              mediaPlanning:{text:`まずは傾聴。そして相手の目的と背景を理解する。<br>
              先入観を持ち、異なる解釈をすることが問題。<br>
              聞く姿勢次第で、解釈する内容が変わる。
              
              `},
              creative:{text:`<span>【思いやりと尊厳を持って話を最後まで聞く】</span><br>
              相手が自分らしく自由に考え発信し行動できるようするために、<br class="isPc">
              時間がかかっても相手の話を最後まで聞く。<br>
              時間がない場合も別の機会を設けるか、時間がないことを真摯に相手に伝える。<br>
              <br>
              <span>【新しい考えに違和感を覚えてもすぐに拒否せず考えてみる】</span><br>
              自分の考えや従来の方法がより優れている。<br>
              そう感じてもすぐに拒否をするのではなく、素直な気持ちで一度考えてみる。
              
              `},
              corporate:{text:`話の腰を折らずに傾聴し、適宜質問等を投げかけ、<br class="isPc">
              相手の発言の意図をくみ取るように心がける。<br>
              立場に関係なく意見を聞くことができる。
              
              `}
            }
          },
          3:{
            title:'否定には理由と代案を示す',
            description:{
              company:{text:`否定することは悪いことではない。<br>
              相手の意見を否定するときは理由と代案を添えることでより良い議論になる。
                                     
              `},
              acountPlanning:{text:`他者からの意見や提案を聞くときには真摯な対応を心がける。<br>
              そのうえで、否定する際には感覚・感情的ではなく、<br class="isPc">
              自分なりの見解・理由づけを含めた会話ができているか、<br class="isPc">
              その理由をもとに自分自身の意見を述べられているかを意識して発言する。
              
              `},
              mediaPlanning:{text:`言いづらいことも、代案があれば上司でも他部署でも議論することができる。<br>
              感情で否定だけするな。<br>
              言わなければ現状は変わらない。もっと直接議論しろ。
              
              `},
              creative:{text:`<span>【断るのではなく可能な範囲を提示する】</span><br>
              依頼の全てに応える必要はないかもしれない、しかし、全てを断るのも違う。<br>
              依頼をする側もされる側も納得して制作を進められるよう<br class="isPc">
              「ここまでならできる」「別の形でやろう」という柔軟な姿勢で対話をする。
              
              `},
              corporate:{text:`相手の意見を否定する際は、<br class="isPc">
              理由と代案を添えることでより良い議論へつなげる。
              
              `}
            }
          }
        }
      },
      6:{
        number:'07', 
        value:`期待を超え続け<br>常にプロフェッショナルであれ`,
        description:`
        <p>
        常に期待を超える仕事を追求していくことで、信頼されるプロフェッショナルとなる。
        </p>
        <p>
        <span>いい仕事かどうかは仲間や顧客や市場が決める。<br>
        つまり自分以外の人が決める。</span>
        </p>
        <p>
        「今までこうやってきた」「この方法しかないだろう」という考え方では<br class="isPc">
        期待を超えることはできない。<br>
        もっといい方法があるはずだと考え、妥協することなく、<br class="isPc">
        周りの声に耳を傾けながら検討し、何度もやり直す。<br>
        この精神が期待を超えることにつながる。
        </p>
        <p>
        <span>自信や誇りと、頑固・惰性の意味を履き違えてはいけない。</span>
        </p>
        <p>
        最善だと思って行っていることでも、見方や思考を変えてみれば、まだまだ工夫、<br class="isPc">
        改善の余地がある。
        </p>
        <p>
        <span>問題に直面したとき、<br class="isPc">
        新たな方法を発見すれば必ず解決できると信じ、<br class="isPc">
        見方や思考を変える。</span>
        </p>
        <p>
        自らの未知や無知を恐れず、謙虚に誠実に、常に期待を超える仕事へ挑戦する。<br>
        期待を超え続けようと追及できるのが私達の考えるプロフェッショナルである。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'常に「より良いもの」を追求する',
            description:{
              company:{text:`現在とっている方法、施策はベストではない。追求、模索の努力を永遠に続ける。
                        
              `},
              acountPlanning:{text:`案件のゴールイメージを持ち、課題に対して積極的に意見・アドバイスを行う。<br>
              導き出す改善策がいつも同じであったり、<br class="isPc">
              その施行によって大きな改善がもたらせていないときは、<br class="isPc">
              自身の行動が最善であるかどうか振り返る姿勢を持つ。
              
              `},
              mediaPlanning:{text:`クライアントのビジネスに目標は合っても限界はない。<br>
              好調時こそ気を引き締める。<br>
              行動量が鈍化すれば、必ず成果は衰退していく。<br>
              好調時こそクライアントのビジネス・自分自身ともに最大化できるチャンスである。<br>
              常に満足せず、新たな施策を打ち、挑戦をし続ける必要がある。<br>
              現状維持は退化である。
              
              `},
              creative:{text:`<span>【常に最適解を探る】</span><br>
              求められている要望に対し、常にその要望を形にするための手段（最適解）を探り、<br class="isPc">
              依頼者がイメージできる形で提案する。
              
              `},
              corporate:{text:`ゴールから逆算して現在地を把握し、<br class="isPc">
              うまくいったこと・いかなかったことについて振り返り、<br class="isPc">
              「改善の余地はないか」を自身に問いかける。
              
              `}
            }
          },
          1:{
            title:'問題解決思考を持つ',
            description:{
              company:{text:`問題とは「あるべき姿」と「現状の自らの状態」との乖離と定義する。<br>
              問題の解決とは、「現状」と「あるべき姿」の乖離を埋める為の思考と行動である。<br>
              <br>
              ・あるべき姿・目標と現状のギャップを洗い出す、つまりは問題の特定をする<br>
              ・問題はどういった条件を揃えれば解決できるのか?と課題の形成を行う<br>
              ・形成した課題に対しての解決策である仮説を立てる<br>
              ・立てた仮説の実行計画を立案する<br>
              ・実行計画を実行し立てた仮説が正しかったか?検証する<br>
              ・仮説が正しくなかった、つまり課題が解決され、その結果問題が解決されなかった場合は再度、仮説を立てなおす、修正し、再度実行計画を立て実行し検証する<br>
              <br>
              これを実施すれば問題解決の糸口となる。<br>
              解決の為の思考と行動がない状態では問題の解決はできない、<br class="isPc">
              論理的、冷静且つ情熱的に問題と向き合う。
                          
              `},
              acountPlanning:{text:`自身の業務や案件における問題に対して、<br class="isPc">
              問題解決の手順に則って解決に向けた取り組みを行う。<br>
              また問題解決にあたっては、仮説と状態目標、<br class="isPc">
              スケジュールをセットで用意しアクションをとる。
              
              `},
              mediaPlanning:{text:`目標から逆算した上で、施策を用意する。<br>
              目標に到達していない場合は、施策の量と質が不足している。<br>
              目標に到達させるための逆算思考で施策を用意するべきである。
              
              `},
              creative:{text:`<span>【自分自身の答えを常に疑う目を持つ】</span><br>
              業務が完成、完了した時に必ず俯瞰で見る目を持ち、<br class="isPc">
              これは本当にクライアントが望んでいるゴールなのかを確認する。
              
              `},
              corporate:{text:`自身が取り組む業務について改善すべき点を明確にし、その解決方法を提案し、<br class="isPc">
              関係者とコミュニケーションや調整を行いながら解決していく。
              
              `}
            }
          },
        }
      },
      7:{
        number:'08', 
        value:`発見精神を持つ`,
        description:`
        <p>
        私達の考える「発見」は、誰もが取り組めること、誰もが見たことのあることから生まれる。<br>
        </p>
        <p>
        <span>それらの見方を変え、新たなより良い考え方、<br class="isPc">
        それを行う為のよい方法を考え、実行し、生み出すことだ。</span>
        </p>
        <p>
        我々の仕事を行う市場における一般的な慣わし、毎日している仕事のあたり前を疑い、
        </p>
        <p>
        <span>新たな仕組み、方法を思案し続け<br class="isPc">
        柔軟に視点を変えた発想をもつ。</span>
        </p>
        <p>
        顧客、Discovery社、および自身成功の為、常に意識し、<br class="isPc">
        念頭に置かなければいけない考え方だ。
        </p>
        `,
        actionAndStance:{
          0:{
            title:'「速さ」を優先し、仮説検証を繰り返す',
            description:{
              company:{text:`「拙速は巧遅に勝る」、計画を立て、まずは「試す」ことが早く良い結果へ辿り着くことにつながる。
                        
              `},
              acountPlanning:{text:`速さを意識した計画設計を自主的に行えているか、<br class="isPc">
              また、それによって試行回数を増やすことができているかを確認する。<br>
              最短スケジュールを自ら設定したうえで、実現のためのアクションを試行する。
              
              `},
              mediaPlanning:{text:`熟考した提案には魂が宿る。<br>
              しかし、時間をかけてしまっては検証がすすまない。<br>
              まずは打席に立つことを優先に考える。そして幾度も幾度も検証し結果を考察する。<br>
              これらをもって反復することで品質は研ぎ澄まされる。
              
              `},
              creative:{text:`<span>【市場への投入を優先する】</span><br>
              市場の反応が得られるのがWEBの強みである。<br>
              市場への投入を優先して、最短で結果を振り返る。<br>
              <br>
              <span>【制作スピードを最大限まで高める】</span><br>
              制作においてスピードは武器である。それは行動スピードも同様である。<br>
              スピードは試行錯誤の回数を担保する大きな力となる。
              
              `},
              corporate:{text:`「速さ」と「確実さ」を業務の中できちんと使い分ける。<br>
              また、そのために方向性を都度確認しながら軌道修正し、<br class="isPc">
              結果として早く完了することを目指す。
              
              `}
            }
          },
          1:{
            title:'PDCAには「記録」がセットである',
            description:{
              company:{text:`Plan（計画）Do（実行）Check（評価）Action（改善）は履歴を記録している者にしかできない。<br>
              「発見」をする為の振り返りができるよう日々、タスク、取り組みの記録をつける。
                          
              `},
              acountPlanning:{text:`日々の活動を記録し、問題/課題/アクションの整理を行う。<br>
              また、整理においては仮説と状態目標、スケジュールをセットで用意する。
              
              `},
              mediaPlanning:{text:`行動を振り返る時間を確保し、リソースを捻出する。<br>
              先週と今週のスケジュールを比較し、日々改善できているか確認する。<br>
              計画と現実を比較し、そのギャップを埋めるための課題と改善策を考え、行動に移す。
              
              `},
              creative:{text:`<span>【アイデア・結論は記録で追えるように】</span><br>
              会議・打ち合わせで生まれたアイデアや結論は、<br class="isPc">
              いつでも振り返られるように、そして他の人に申し伝えられるように記録化する。<br>
              <br>
              <span>【検証結果を必ず確認する】</span><br>
              制作物は検証結果まで把握して制作完了とする。<br>
              制作物には仮説の検証がセットにあるので、必ずその結果を確認する。
              
              `},
              corporate:{text:`月次で、期待されていることや役割・目標に対する結果と<br class="isPc">
              その要因を振り返り、次の一手を共有する。
              
              `}
            }
          },
          2:{
            title:'当たり前を疑え',
            description:{
              company:{text:`日々の業務と自分に対し振り返り、客観化･相対化する視点を持つ。<br>
              その為に、時に仲間や上司など立場を超え、広く助言を求めたり議論を行う。
                          
              `},
              acountPlanning:{text:`自業務の振り返りにおいて、常に問題課題を設定しておく。<br>
              振り返りに際し、周囲に相談することで客観的・相対的な視点を付与する。<br>
              自身で問題が見つけられない場合には、周囲に相談し問題課題を見つけるよう動く。
              
              `},
              mediaPlanning:{text:`思い込みを疑う。<br>
              確証がないまま業務を進めたり、間違うはずがないと慢心すると大きな失敗につながる。<br>
              自身の思い込みを疑い、確証がなければ確認を徹底し、<br class="isPc">
              双方の認識に齟齬がなくなるまで議論をする。
              
              `},
              creative:{text:`<span>【新しい発想を得るために、いままでの行動を変えてみる】</span><br>
              たくさん書いてみたり、ザッピングしてみたり、<br class="isPc">
              力を抜いてみたり、離れてみたり、全く違うジャンルに触れてみたり…<br>
              いままでと違う自分が生み出したアイデアが素晴らしい結果になるかもしれない。<br>
              制作の可能性は無限大、大胆に、勇敢に、アイデアを模索する。
              
              `},
              corporate:{text:`日常業務でもベストな方法を取れているかを検討し、<br class="isPc">
              チーム内外にも助言を求め、改善活動を実施する。
              
              `}
            }
          },
        }
      },
    }
  }