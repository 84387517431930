// CommentFormComponent.tsx
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../AppContext';
import { Link, useLocation } from 'react-router-dom';
import { mission,Mission } from '../data/Mission';
import { value, Value} from '../data/Value';
import { departments,Departments } from '../data/Departments';
import { addDoc, collection, Timestamp } from "firebase/firestore";
import { db } from '../DB/firebase';
import { removeBrTags, nl2br } from './Utils/helpers';
import { FaRegComment } from "react-icons/fa6";




const CommentFormComponent: React.FC = () => {

    const location = useLocation();

    const {
        // isScreen,
        // setIsScreen,
        user,
        isDepartment,
        commentCategoryInfo,
        setCommentCategoryInfo,
        isCommentFormOpen,
        setIsCommentFormOpen,
        userInputCommentFormText,
        setUserInputCommentFormText
      } = useAppContext();

    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [isCommentConfirm, setIsCommentConfirm] = useState<boolean>(false);


  



    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setSuccess('');
    
        if (!user) {
          setError('ログインしてください。');
          return;
        }
    
        if (!userInputCommentFormText) {
          setError('コメントを入力してください。');
          return;
        }
    
        setIsCommentConfirm(true);
      };
    
      const handleConfirm = async () => {
        if (!user) {
          setError('ログインしてください。');
          return;
        }
    
        if(commentCategoryInfo ==null){
            setError('commentCategoryInfoがnullです');
            return;
        }
    
        try {
          const docRef = await addDoc(collection(db, 'comments'), {
            user_id: user.uid,
            status: true,
            category: commentCategoryInfo.category,
            sub_category: commentCategoryInfo.sub_category,
            comment: userInputCommentFormText,
            created: Timestamp.now(),
          });
    {/*
          const addedComment: Comment = {
            id: docRef.id,
            user_id: user.uid,
            status: true,
            category: category,
            sub_category: sub_category,
            comment: comment,
            created: Timestamp.fromDate(new Date()),
          };
    
          setComments(prevComments => prevComments ? [addedComment, ...prevComments] : [addedComment]);
        */}
        setUserInputCommentFormText('');
          setSuccess('コメントを投稿しました。');
        } catch (error: any) {
          setError(error.message);
          setTimeout(() => {
            setError('');
          }, 3000);
        } finally {
          setIsCommentConfirm(false);
          setTimeout(() => {
            setSuccess('');
          }, 3000);
        }
        
      };
    
      const handleCommentFormClose = () =>{
        setIsCommentFormOpen(false);
        setCommentCategoryInfo(null);
        setIsCommentConfirm(false);
      }
    
      const handleEditComment = () =>{
        setIsCommentConfirm(false);
      }

    useEffect(() => {
        setIsCommentConfirm(false);
        //setCommentCategoryInfo(null);//コメントフォームのカテゴリー情報削除
    }, [location]);

    return(
      <div className={`footer__commentForm ${isCommentFormOpen ? 'active':''}`}>
        <p className="footer__commentForm__target-contents-info">
            <span><FaRegComment className="footer__commentForm__target-contents-info__icon" />{commentCategoryInfo?.category === "mission" ? ('MISSION'):('VALUE')}</span>

            {commentCategoryInfo?.sub_category && Object.keys(commentCategoryInfo.sub_category).length > 0 && (
            <>
                <span>0{Number(commentCategoryInfo?.sub_category.valueNum) + 1} </span>
                {commentCategoryInfo.sub_category.hasOwnProperty('valueNum') && (
                <span>{removeBrTags(value.values[commentCategoryInfo?.sub_category.valueNum].value)}</span>
                )}
                {commentCategoryInfo.sub_category.hasOwnProperty('actionAndStanceNum') && (
                <>
                <span> ≫ ACTION & STANCE ≫ </span>
                <span>{removeBrTags(value.values[commentCategoryInfo?.sub_category.valueNum].actionAndStance[commentCategoryInfo?.sub_category.actionAndStanceNum].title)} ≫ </span>
                <span>{departments[isDepartment]}</span>
                </>
                )}
            </>
            )}

            <span> にコメントします</span>
        </p>
        {isCommentConfirm ?(
          <div className="comments__confirm">
            <p className="comments__confirm__comment" dangerouslySetInnerHTML={{ __html: nl2br(userInputCommentFormText) }} />
            <div className="comments__confirm__btns">
              <button className="comments__confirm__btns__submitBtn" onClick={()=>handleConfirm()}>この内容でコメント</button>
              <button className="comments__confirm__btns__editBtn" onClick={()=>handleEditComment()}>修正する</button>
            </div>
          </div>
        ) : (
        <form className="comments__form" onSubmit={handleSubmit}>
            {
            <textarea
            id="comment"
            value={userInputCommentFormText}
            onChange={(e) => setUserInputCommentFormText(e.target.value)}
            required
        />}
            <div className="comments__btns">
                <button className="comments__btns__formConfirmBtn" type="submit">コメント内容を確認</button>
                <button className="comments__btns__formCloseBtn" type="button" onClick={()=>handleCommentFormClose()}>フォームを閉じる</button>
            </div>
            {success &&(
              <div className="comments__form__message">
                <p className="comments__form__successMessage">{success}</p>
              </div>
            )}
            {error &&(
              <div className="comments__form__message">
                <p className="comments__form__errorMessage">{error}</p>
              </div>
            )}
        </form>
        )}
      </div>
    )
}

export default CommentFormComponent;