import React, { useState, useEffect,useRef }  from 'react';
import { useAppContext } from '../AppContext';
import { Link,useParams,useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Value, value} from '../data/Value';
import { Departments, departments} from '../data/Departments';
import Animated from './Utils/components/AnimatedComponent';
import useScrollToTopOnLocationChange from './Hooks/useScrollToTopOnLocationChangeHook';
import { addClassToSpanTags, WrapPTagsWithAnimated, removeBrTags, useIntersectionObserver, TogglePinnedElementClick, LinkArrow} from './Utils/helpers';
import CommentComponent from './CommentComponent';
import FlagsComponent from './FlagsComponent';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


interface RouteParams {
    value_num: string;
    department_name: string;
    [key: string]: string | undefined;
}

interface ActionAndStanceComponentProps {
    value: Value;
}   

  const ActionAndStanceComponent: React.FC<ActionAndStanceComponentProps> = ({ value }) => {
    
    const location = useLocation();
    const { value_num, department_name } = useParams<RouteParams>();
    const valueNum = value_num ? parseInt(value_num, 10) -1 : 0; // 文字列から数値に変換、undefinedの場合は0
    const departmentName = department_name ? department_name : 'company';

    const {
        // isScreen,
        // setIsScreen,
        // isValueList,
        // setIsValueList,
        // isValueDetail,
        // setIsValueDetail,
        isValueNum,
        setIsValueNum,
        // isActionAndStance,
        // setIsActionAndStance,
        // isActionAndStanceNum,
        // setIsActionAndStanceNum,
        isDepartment,
        setIsDepartment,
        user,
        // comments,
        pinnedElements,
        setPinnedElements,
        isAnnivContents,
    } = useAppContext();

  
    const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});

    // アコーディオンの展開状態を制御する関数
    const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpandedItems((prev) => ({
        ...prev,
        [panel]: isExpanded,
        }));
    };

    // useEffectでDOMからデータ属性を読み取る
    useEffect(() => {
        // 例えば、data-expand属性を持つ要素を選択する
        const accodionItems = document.querySelectorAll('[data-expand]');
        const newExpandedItems: { [key: string]: boolean } = {};
        
        accodionItems.forEach(item => {
        const id = item.getAttribute('data-expand');
        if (id) {
            newExpandedItems[id] = false;
        }
    });
    
    setExpandedItems(newExpandedItems);
  }, []);

    

    //const actionAndStances = Object.values(value.values[isValueNum].actionAndStance);

    // value -> No. title descriptionリンク　アクスタリンク（タイトル一覧）
    // valueDetail ->  No. title description 他valueリスト　アクスタリンク（タイトル一覧）
    // actionAndStance -> No. title descriptionリンク　スライダー形式　部署ごとの切り替えリンク
    

    // 部署ごとのstyle
    const getDepartmentStyle = (): React.CSSProperties => {
        if(isDepartment == 'acountPlanning') {
            return{
                background:'#e94829',
                color:'#fff'
            }
        } else if(isDepartment == 'mediaPlanning') {
            return{
                background:'#f18d00',
                color:'#fff'
            }
        } else if(isDepartment == 'creative') {
            return{
                background:'#37bef0',
                color:'#fff'
            }
        } else if(isDepartment == 'corporate') {
            return{
                background:'#8fc31f',
                color:'#fff'
            }
        } else {
            return{
                background:'#000',
            }
        }
    };

    //ページが切り替わった際にトップにスクロールするHook(useEffect)
    useScrollToTopOnLocationChange();

    // ページのデフォルトのstateをセット
    useEffect(() => {
        setIsValueNum(valueNum);
        setIsDepartment(departmentName);
    }, [location]);
    
    // descriptionのpタグにpinnedクラスを付与する
    useEffect(() => {
        console.log('pinnedElements',pinnedElements)

        const pElements = document.querySelectorAll('.actionAndStance__contents__actionList__description p');
        
        const handleClick = (event: MouseEvent) => {
            console.log('click')
            const targetElement = event.target as HTMLElement;
            // targetElementがspanタグであれば、その最も近い親のp要素を取得する
            let pElement
            if(targetElement.tagName.toLowerCase() === 'span'){
                pElement = targetElement.closest('p');
            } else {
                pElement = targetElement;
            }
            if(!pElement){
                return;
            }
            let newPinnedElementsArray = TogglePinnedElementClick(pElement, pinnedElements);
            setPinnedElements(newPinnedElementsArray);
        };

        pElements.forEach(p => {
            const pElement = p as HTMLElement; // 型アサーションを使用してHTMLElementとして扱う

            if(pinnedElements.length > 0 && pinnedElements.includes(pElement.id)){
                console.log('pElement',pElement);
                pElement.classList.add('pinned');
            }

            pElement.addEventListener('click', handleClick);
        });
        
        // Cleanup function to remove event listeners
        return () => {
            pElements.forEach(p => {
                const pElement = p as HTMLElement; // 型アサーションを使用してHTMLElementとして扱う
                pElement.removeEventListener('click', handleClick);
            });
        };
    }, [pinnedElements,isDepartment,isValueNum]);


    const valueDetail = value.values[isValueNum];

    return(
        <div className="value">
            <header className="value__header">
                <Link to={"/menu/"} className="value__header__menu clickable">
                    MENU
                </Link>
                <Link to={"/value/"} className="clickable">
                    <span className="value__header__title">{value.title}</span>
                    <span className="value__header__subTitle">{value.subTitle}</span>
                </Link>
            </header>
            <div className="actionAndStance">
                <Link to={`/value/detail/${value_num}/`} className="clickable">
                    <header className="actionAndStance__header">
                        <div className="actionAndStance__header__number--wrap">
                            <span className="actionAndStance__header__numberValue">VALUE</span>
                            <span className="actionAndStance__header__number">{valueDetail.number}</span>
                        </div>
                        <span className="actionAndStance__header__title">{removeBrTags(valueDetail.value)}</span>
                    </header>
                </Link>
                <div className="actionAndStance__contents">
                    <p className="actionAndStance__contentsTitle">ACTION & STANCE</p>
                    <div className="actionAndStance__contents__departmentsBtn--wrap">
                        <p className="actionAndStance__contents__departmentsBtnTitle">部署ごとの ACTION & STANCEを見る</p>
                        <ul className="actionAndStance__contents__departmentBtn">
                            <li><Link to={`/value/detail/${value_num}/action_and_stance/company/`} className={`actionAndStance__contents__departmentBtn--company ${isDepartment === 'company' ? 'active' : ''}`} onClick={()=>{setIsDepartment('company')}}>ディスカバリー全社</Link></li>
                            <li><Link to={`/value/detail/${value_num}/action_and_stance/acountPlanning/`} className={`actionAndStance__contents__departmentBtn--acountPlanning ${isDepartment === 'acountPlanning' ? 'active' : ''}`} onClick={()=>{setIsDepartment('acountPlanning')}}>営業部</Link></li>
                            <li><Link to={`/value/detail/${value_num}/action_and_stance/mediaPlanning/`} className={`actionAndStance__contents__departmentBtn--mediaPlanning ${isDepartment === 'mediaPlanning' ? 'active' : ''}`} onClick={()=>{setIsDepartment('mediaPlanning')}}>運用部</Link></li>
                            <li><Link to={`/value/detail/${value_num}/action_and_stance/creative/`} className={`actionAndStance__contents__departmentBtn--creative ${isDepartment === 'creative' ? 'active' : ''}`} onClick={()=>{setIsDepartment('creative')}}>制作部</Link></li>
                            <li><Link to={`/value/detail/${value_num}/action_and_stance/corporate/`} className={`actionAndStance__contents__departmentBtn--corporate ${isDepartment === 'corporate' ? 'active' : ''}`} onClick={()=>{setIsDepartment('corporate')}}>経営企画部／経営管理部</Link></li>
                        </ul>
                    </div>
                    <p className="actionAndStance__contents__department__name">{departments[isDepartment]}</p>
                

                    <div className="actionAndStance__contents__actionList--wrap">
                        {Object.entries(valueDetail.actionAndStance).map(([subKey, action],index) => (
                            <div id={`action${Number(subKey) + 1}`} className="actionAndStance__contents__actionList" key={`value${isValueNum}_action${Number(subKey) + 1}_${isDepartment}`}>
                                <Animated>
                                    <div className="actionAndStance__contents__actionList__action--wrap">
                                        <h4 className="actionAndStance__contents__actionList__action" style={{...getDepartmentStyle()}}>{action.title}</h4>
                                        <FlagsComponent category='value' sub_category={{valueNum:isValueNum,actionAndStanceNum:subKey,department:isDepartment}} />
                                    </div>
                                    
                                    <div className="actionAndStance__contents__actionList__description">
                                        {/* ここでなぜidを振っていたのか？アニメーションがおかしくなるためid無しに変更 <div dangerouslySetInnerHTML={{ __html: addIdToActionAndStancePTags(addClassToSpanTags(action.description[isDepartment].text, 'text--strong'),`value_${value_num}_actionAndStance_${Number(subKey) + 1}_${isDepartment}`)}} />*/}
                                        {/* <p key={`value_${value_num}_actionAndStance_${Number(subKey) + 1}_${isDepartment}`} id={`value_${value_num}_actionAndStance_${Number(subKey) + 1}_${isDepartment}`} dangerouslySetInnerHTML={{ __html: addClassToSpanTags(action.description[isDepartment].text, 'text--strong')}} /> */}
                                        <p id={`value_${value_num}_actionAndStance_${Number(subKey) + 1}_${isDepartment}`} dangerouslySetInnerHTML={{ __html: addClassToSpanTags(action.description[isDepartment].text, 'text--strong')}} />
                                        {isAnnivContents && user &&(
                                        <div className="actionAndStance__contents__actionList__description__comments-wrap">
                                        <Accordion
                                            // key={`value_${value_num}_actionAndStance_${Number(subKey) + 1}_${isDepartment}_accordion`}
                                            expanded={!!expandedItems[`${subKey}_${isDepartment}`]}
                                            onChange={handleAccordionChange(`${subKey}_${isDepartment}`)}
                                            data-expand={subKey} // データ属性を追加
                                            >
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                            {!expandedItems[`${subKey}_${isDepartment}`] ? (
                                                <button className="actionAndStance__contents__actionList__description__comments__btn">コメントを見る<span>▼</span></button>
                                            ):(
                                                <button className="actionAndStance__contents__actionList__description__comments__btn">コメントを閉じる<span>▲</span></button>
                                            )}
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="actionAndStance__contents__actionList__description__comments">
                                                    <CommentComponent category='value' sub_category={{valueNum:isValueNum,actionAndStanceNum:subKey,department:isDepartment}} />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                        </div>
                                        )}
                                    </div>
                                </Animated>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActionAndStanceComponent;
