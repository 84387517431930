import React, { useState, useEffect,useRef }  from 'react';
import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Mission, mission } from '../data/Mission';
import Animated from './Utils/components/AnimatedComponent';
import useScrollToTopOnLocationChange from './Hooks/useScrollToTopOnLocationChangeHook';
import { addClassToSpanTags, WrapPTagsWithAnimated, removeBrTags, useIntersectionObserver, TogglePinnedElementClick,LinkArrow } from './Utils/helpers';
import CommentComponent from './CommentComponent';
import FlagsComponent from './FlagsComponent';



interface MissionComponentProps {
    mission: Mission;
}

const MissionComponent: React.FC<MissionComponentProps> = ({ mission }) => {
    
    const {pinnedElements,setPinnedElements} = useAppContext();

    const targetRef = useRef<HTMLDivElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    const handleIntersection = (isIntersecting: boolean) => {
        setIsVisible(!isIntersecting);
    };

    //titleのheaderを出現させるターゲット
    useIntersectionObserver(targetRef, handleIntersection, { threshold: 0.8 });
    
    //ページが切り替わった際にトップにスクロールするHook(useEffect)
    useScrollToTopOnLocationChange();

    // descriptionのpタグにpinnedクラスを付与する
    useEffect(() => {
        const pElements = document.querySelectorAll('.mission__contents__description p');
        
        const handleClick = (event: MouseEvent) => {
            const targetElement = event.target as HTMLElement;
            // targetElementがspanタグであれば、その最も近い親のp要素を取得する
            let pElement
            if(targetElement.tagName.toLowerCase() === 'span'){
                pElement = targetElement.closest('p');
            } else {
                pElement = targetElement;
            }
            if(!pElement){
                return;
            }
            let newPinnedElementsArray = TogglePinnedElementClick(pElement, pinnedElements);
            setPinnedElements(newPinnedElementsArray);
        };

        pElements.forEach(p => {
            const pElement = p as HTMLElement; // 型アサーションを使用してHTMLElementとして扱う
            pElement.addEventListener('click', handleClick);
        });
        
        // Cleanup function to remove event listeners
        return () => {
            pElements.forEach(p => {
                const pElement = p as HTMLElement; // 型アサーションを使用してHTMLElementとして扱う
                pElement.removeEventListener('click', handleClick);
            });
        };
    }, [pinnedElements]);


    
  return(
    <section className="mission">
        <div className="mission--bg">
          <img className="mission--bg__kv" src={`${process.env.PUBLIC_URL}/images/icon--kv.svg`} />
        </div>
        <div className="mission--inner">
            <header className="mission__header">
                <Link to={"/menu/"} className="mission__header__menu clickable">
                    MENU
                </Link>
                <Link to={"/mission/"} className="clickable">
                    <span className="mission__header__title">{mission.title}</span>
                    <span className="mission__header__subTitle">{mission.subTitle}</span>
                </Link>
            </header>
         
            <motion.header
                initial={{ opacity: 0 }}
                animate={{ opacity: isVisible ? 1 : 0 }}
                transition={{ duration: 0.2 }}
            >
                <header className="mission__contentsHeader">
                    <p className="mission__contentsHeader__titleIcon">CORPORATE MISSION</p>
                    <p className="mission__contentsHeader__titleText" dangerouslySetInnerHTML={{ __html: removeBrTags(mission.text) }} />
                </header>
            </motion.header>
            <div className="mission__contents">
                <div ref={targetRef} className="mission__contents__title--wrap">
                    <div className="mission__contents__titleIcon--wrap">
                        <p className="mission__contents__titleIcon">CORPORATE MISSION</p>
                        <FlagsComponent category='mission' sub_category={{}}/>
                    </div>
                    <p className="mission__contents__titleText" dangerouslySetInnerHTML={{ __html: mission.text }} />
                </div>
                <div className="mission__contents__description">{WrapPTagsWithAnimated(mission.description)}</div>
                {/*<Animated animation="scaleUp">
                    <Link to={"/mission/introduction/"}className="mission__contents__link clickable">私たちのミッションを実現するために ≫</Link>
  </Animated>*/}
                <Animated animation="scaleUp">
                    <Link to={"/value/"} className="mission__contents__link clickable">VALUEへ<LinkArrow /></Link>
                </Animated>
                <CommentComponent category='mission' sub_category={{}}/>
            </div>
        </div>
    </section>
  )
}

export default MissionComponent;