import React, { useState, useEffect }  from 'react';
import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import LogoComponent from './LogoComponent';
import { Mission, mission } from '../data/Mission';
import { Value, value } from '../data/Value';
import { Departments, departments } from '../data/Departments';
import Animated from './Utils/components/AnimatedComponent';
import useScrollToTopOnLocationChange from './Hooks/useScrollToTopOnLocationChangeHook';
import { motion, AnimatePresence } from 'framer-motion';
import { removeBrTags,LinkArrow } from './Utils/helpers';
import { FaCircleArrowRight } from "react-icons/fa6";


interface MenuComponentProps {
    mission: Mission;
    value: Value;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ mission,value }) => {

    const [isDesktop, setIsDesktop] = useState<boolean>(window.matchMedia('(min-width: 1024px)').matches);

    //ページが切り替わった際にトップにスクロールするHook(useEffect)
    useScrollToTopOnLocationChange();

    useEffect(() => {
        // Function to handle device size change
        const mediaQuery = window.matchMedia('(min-width: 1024px)');
        const handleResize = (e: MediaQueryListEvent) => {
            setIsDesktop(e.matches);
            adjustMenuPageHeightWhenDeviceIsMobile();
        };

        // Initial adjust on mount
        adjustMenuPageHeightWhenDeviceIsMobile();

        // Attach event listeners
        mediaQuery.addEventListener('change', handleResize);
        window.addEventListener('resize', adjustMenuPageHeightWhenDeviceIsMobile);

        // Cleanup event listeners on unmount
        return () => {
            mediaQuery.removeEventListener('change', handleResize);
            window.removeEventListener('resize', adjustMenuPageHeightWhenDeviceIsMobile);
        };
    }, [isDesktop]);


    const adjustMenuPageHeightWhenDeviceIsMobile = () =>{
        const menuPageContent = document.querySelector('section.menu') as HTMLElement | null;
        const clientWindowHeight = window.innerHeight;
        const headerHeight = document.querySelector('.logo--header')?.clientHeight || 0;
        const menuHeaderHeight = document.querySelector('.menu__header')?.clientHeight || 0;
        const footerHeight = document.querySelector('.footer')?.clientHeight || 0;
        let adjustHeight;
        if(!isDesktop){
            adjustHeight = clientWindowHeight - headerHeight - menuHeaderHeight - footerHeight;
        } else {
            adjustHeight = clientWindowHeight - headerHeight - menuHeaderHeight;
        }
        // 高さを px 単位で指定
        if (menuPageContent) {
            menuPageContent.style.height = `${adjustHeight}px`;
        }
    }



    return(
        <section className="menu">
            <div className="menu__inner">
                <div className="menu__header">
                    <Link to={"/menu/"} className="menu__header__title">MENU</Link>
                </div>
                <div className="menu__contents--wrap">
                    <div className="menu__contents">
                        <Animated animation="scaleUp">
                        <div className="menu__contents--inner">
                            <Link to={"/mission/"} className="menu__contents__title--wrap clickable">
                                <div className="menu__contents__title--wrap">
                                    <div className="menu__contents__title">
                                        MISSION
                                    </div>
                                    <p className="menu__contents__subTitle">
                                        私たちの「存在意義」「目指す未来」
                                    </p>
                                </div>
                                <div className="menu__contents__mission__title--wrap">
                                    <p className="menu__contents__mission__title">
                                        めっちゃいい買い物を<br />
                                        たくさんしてもらう
                                    </p>
                                </div>
                            </Link>
                            <Animated animation="scaleUp">
                                <Link to={"/mission/"} className="menu__contents__mission__titleDescription clickable">
                                    DESCRIPTION<LinkArrow />
                                </Link>
                            </Animated>
                        </div>
                        </Animated>
                    </div>
                    <div className="menu__contents">
                        <Animated animation="scaleUp">
                        <div className="menu__contents--inner">
                        <Link to={"/value/"} className="menu__contents--value__title--wrap clickable">
                            <div className="menu__contents__title">
                                VALUE
                            </div>
                            <p className="menu__contents__subTitle">
                                私たちらしい「あり方」・「価値観」として以下を掲げ、ミッションに向けて行動します。
                            </p>
                        </Link>
                        <div className="menu__contents__valueList">
                            {Object.entries(value.values).map(([key, valueDetail]) => (
                            
                            <div key={`menu_valuelist_${key + 1}`}>
                                <Animated animation="scaleUp">
                                <Link to={`/value/detail/${Number(Number(key) + 1)}/`}
                                    className="clickable"
                                >
                                    <span className="menu__contents__valueList__num">{valueDetail.number}.</span>
                                    <span>{removeBrTags(valueDetail.value)}</span><LinkArrow />
                                </Link>
                                </Animated>
                            </div>
                            ))}
                        </div>
                        </div>
                        </Animated>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MenuComponent;