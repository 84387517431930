import React, { useState, useEffect,useRef }  from 'react';
import { useAppContext } from '../AppContext';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Value, value} from '../data/Value';
import Animated from './Utils/components/AnimatedComponent';
import useScrollToTopOnLocationChange from './Hooks/useScrollToTopOnLocationChangeHook';
import { removeBrTags,LinkArrow } from './Utils/helpers';
import CommentComponent from './CommentComponent';
import FlagsComponent from './FlagsComponent';

  interface ValueComponentProps {
    value: Value;
  }

  const ValueComponent: React.FC<ValueComponentProps> = ({value}) => {
    const {
        // isScreen,
        // setIsScreen,
        // isValueList,
        // setIsValueList,
        // isValueDetail,
        // setIsValueDetail,
        // isValueNum,
        // setIsValueNum,
        // isActionAndStance,
        // setIsActionAndStance,
        // isActionAndStanceNum,
        // setIsActionAndStanceNum,
        // isDepartment,
        // setIsDepartment,
        // user,
        // comments
    } = useAppContext();


    //ページが切り替わった際にトップにスクロールするHook(useEffect)
    useScrollToTopOnLocationChange();


    return (
        <div className="value">
            <header className="value__header">
                <Link to={"/menu/"} className="value__header__menu clickable">
                    MENU
                </Link>
                <Link to={"/value/"} className="clickable">
                    <span className="value__header__title">{value.title}</span>
                    <span className="value__header__subTitle">{value.subTitle}</span>
                </Link>
            </header>
            <div className="value__valuelist">
            {Object.entries(value.values).map(([key, valueDetail]) => (
            <Animated animation='scaleUp' key={`valuelist_${key}`}>
                <Link to={`/value/detail/${Number(Number(key) + 1)}/`} className="value__valuelist__title clickable">
                    <span className="value__valuelist__title__num">{valueDetail.number}.</span>
                    <span>{removeBrTags(valueDetail.value)}</span>
                    <LinkArrow />
                </Link>
            </Animated>
            ))}
            </div>
        </div>
    );
  }

  export default ValueComponent;
