import React, { useEffect }  from 'react';
import { useAppContext } from '../AppContext';
import { Link,useLocation } from 'react-router-dom';
import { Mission, mission } from '../data/Mission';
import { Value, value } from '../data/Value';
import { Departments, departments } from '../data/Departments';
import { motion } from 'framer-motion';
import { removeBrTags } from './Utils/helpers';
import { FaRegFileLines } from "react-icons/fa6";






interface NavigationComponentProps {
    mission: Mission;
    value: Value;
    departments: Departments;
}

const NavigationComponent: React.FC<NavigationComponentProps> = ({ mission,value,departments }) => {
    const location = useLocation();

    const {
        isDepartment,
        setIsDepartment,
        isNavModalOpen,
        isAnnivContents,
    } = useAppContext();


    const YouAreHere : React.FC = () =>{
        return(
            <span id="js-youAreHere" className="nav__youAreHere"></span>
        )
    }

    useEffect(() => {
        const youAreHereBtn = document.getElementById('js-youAreHereBtn');
        const youAreHereElm = document.getElementById('js-youAreHere');
        const navigation = document.getElementById('js-navigation');

        const handleClick = (e:any) =>{
            e.preventDefault();
            const SCROLLMARGIN = 50;
            const HEADER_HEIGHT = Number(SCROLLMARGIN);
            setTimeout(() => {

                const elementPosition = youAreHereElm?.getBoundingClientRect().top;
                const navigationScrollTop = navigation?.scrollTop || 0;
                const offsetPosition = elementPosition ? elementPosition + navigationScrollTop - HEADER_HEIGHT : 0;

                navigation?.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                });
            },200);
        }

        if (youAreHereBtn) {
            youAreHereBtn.addEventListener('click',handleClick)
        }
    
        // Cleanup function to remove event listeners
        return () => {
            youAreHereBtn?.removeEventListener('click', handleClick);
        };
    }, [isNavModalOpen]);


    const NavComponent : React.FC  = () => {
        
        const getNavClassName = () => {
            if(isNavModalOpen){
                return(
                    'nav active'
                )
            } else {
                return(
                    'nav'
                )
            }
        }


        return(
            <motion.section
                className={getNavClassName()}
                // key="nav"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ backgroundColor: 'red' }}
                //transition={{ duration: 0.5 }}
                id="js-navigation"
            >
                <div className="nav__section--home clickable">
                    <div className="nav__section--home__title-wrap">
                        <div className="nav__section--home__title">
                            <Link to={"/"}>
                                <FaRegFileLines className="nav__pageIcon" />HOME{location.pathname === '/' && (<YouAreHere/>)}{/*<LogoComponent componentName="NavigationComponent" text='VALUEBOOK' />*/}
                            </Link>
                        </div>
                        {isAnnivContents &&( 
                            <Link to={"/mypage/"} className="nav__section--home__mypage-title clickable">
                                <FaRegFileLines className="nav__pageIcon" />MYPAGE{location.pathname === '/mypage/' && (<YouAreHere/>)}
                            </Link>
                        )}
                    </div>
                    <div className="nav__section--menu clickable">
                        <div className="nav__section--menu__title">
                            <Link to={"/menu/"}>
                                <FaRegFileLines className="nav__pageIcon" />MENU{location.pathname === '/menu/' && (<YouAreHere/>)}
                            </Link>
                        </div>
                        <Link to={"/mission/"} className="nav__section--mission clickable">
                             <div className="nav__section--mission__title">
                                <FaRegFileLines className="nav__pageIcon" />MISSION{location.pathname === '/mission/' && (<YouAreHere/>)}
                            </div>
                            <p className="nav__section--mission__item clickable">DESCRIPTION</p>
                            <p className="nav__section--mission__subTitle">「{removeBrTags(mission.text)}」</p>
                        </Link>
                        <div className="nav__section--value clickable">
                            <Link to={"/value/"}>
                            <div className="nav__section--value__title"><FaRegFileLines className="nav__pageIcon" />VALUE{location.pathname === '/value/' && (<YouAreHere/>)}</div>
                           </Link>
                            <div className="nav__section--value__items--wrap">
                                {Object.entries(value.values).map(([key, valueDetail]) => (
                                
                                <div className="nav__section--value__item clickable" key={`nav_value_item_${key}`}>
                                    <Link to={`/value/detail/${Number(key) + 1}/`}>
                                        <div className="nav__section--value__item__title">
                                            <FaRegFileLines className="nav__pageIcon" />
                                            <span className="nav__section--value__item__title__valueNum">VALUE {valueDetail.number} </span>
                                            {location.pathname === `/value/detail/${Number(key) + 1}/` && (<YouAreHere/>)}
                                            <br />
                                            <span>{removeBrTags(valueDetail.value)}</span>
                                        </div>
                                        <div className="nav__section--value__item__subTitle">
                                            DESCRIPTION
                                        </div> 
                                    </Link>  
                                    <div className="nav__section--value__item__actionAndStance">
                                        <div className="nav__section--value__item__actionAndStance__title">
                                            <Link to={`/value/detail/${Number(key) + 1}/action_and_stance/${isDepartment}/`}>
                                                <FaRegFileLines className="nav__pageIcon" />ACTION & STANCE
                                            </Link>
                                        </div>
                                        {Object.entries(departments).map(([departmentKey, department]) => (
                                            <Link to={`/value/detail/${Number(key) + 1}/action_and_stance/${departmentKey}/`}
                                                className="nav__section--value__item__actionAndStance__department clickable" key={`nav_value_item_${Number(key) + 1}_action_${departmentKey}`} onClick={()=>{setIsDepartment(departmentKey)}}>
                                                <FaRegFileLines className="nav__pageIcon" />{department}{location.pathname === `/value/detail/${Number(key) + 1}/action_and_stance/${departmentKey}/` && (<YouAreHere/>)}
                                            </Link>
                                        ))}
                                    </div>  
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <a id="js-youAreHereBtn" className="nav__youAreHereBtn" href="#js-youAreHere"><span className="nav__youAreHere"></span><span>現在地へ</span></a>
            </motion.section>
        )
    }



    return(
        <NavComponent /> 
    )
}

export default NavigationComponent;