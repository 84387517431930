import React, { useState } from 'react';
import { useAppContext } from '../AppContext';
import { Link, useLocation } from 'react-router-dom';
import LogoComponent from '../components/LogoComponent';
import { Switch, FormControlLabel} from '@mui/material';


const HeaderComponent: React.FC = () => {

  const location = useLocation();
  
  const {
    // isScreen,
    // setIsScreen,
    user,
    isAnnivContents,
    isAutoZoomMode,
    setIsAutoZoomMode,
  } = useAppContext();

  const [switchState, setSwitchState] = useState(true);

  const handleSwitchChange = (event:any) => {
    setSwitchState(event.target.checked);
    setIsAutoZoomMode(!isAutoZoomMode);
  };

    return(
      <>
      {location.pathname !== '/' && (
        <header className="logo--header">
            <LogoComponent componentName="" text='VALUEBOOK' />
            <FormControlLabel
                    control={<Switch checked={switchState} onChange={handleSwitchChange} />}
                    label="AutoZoomモード"
                    className="logo--header__zoom-switch"
                />
            {/*
            {isAnnivContents && (
            <Link to="/mypage/" className="annivContents__logo--header__mypageBtn clickable">
              {user ? (
                <>マイページ</>
              ):(
                <>ログイン</>
              )}
            </Link>
          )}*/}
        </header>
        )}
      </>
    )
  }

export default HeaderComponent;